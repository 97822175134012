/********** INSTANTANEOUS ***********************************/
var oCarMediaSlider, oCarThumbsSlider, oModalMediaSlider, hasVivitionFullyLoaded;
var thumbDiv;

var win = $(window);

// Global so that it will be available in cardetail.ts
var oComparePackagesSlider;

// Global so that they can be destroyed when Knockout replaces the HTML of a slider
var oCarImgsSlider;

var borderCarFormSubmitted = false;

$.fn.matchHeight._throttle = 40;

/** SCROLL INDICATOR MODULE - begin **/

const showScrollIndicator = document.querySelector('.js-progressbar');

if (showScrollIndicator) {
    document.addEventListener('scroll', _handleScrollEvent);
}

function _handleScrollEvent() {
    const progressbar = document.querySelector('.js-progressbar-container'),
        scrollFunction = () => {
            const myBar = progressbar.querySelector('#myBar'),
                winScroll = document.body.scrollTop || document.documentElement.scrollTop,
                height = document.documentElement.scrollHeight - document.documentElement.clientHeight,
                scrolled = (winScroll / height) * 100;

            myBar.style.width = scrolled + "%";
        };

    progressbar.style.display = 'block';

    requestAnimationFrame(scrollFunction);
}

/** SCROLL INDICATOR MODULE - end **/

/** Input Filter MODULE - begin **/

function setInputFilter(textbox, inputFilter) {
    ["input", "keydown", "keyup", "mousedown", "mouseup", "select", "contextmenu", "drop"].forEach(function (event) {
        if (textbox) {
            textbox.addEventListener(event, function () {
                if (inputFilter(this.value)) {
                    this.oldValue = this.value;
                    this.oldSelectionStart = this.selectionStart;
                    this.oldSelectionEnd = this.selectionEnd;
                } else if (this.hasOwnProperty("oldValue")) {
                    this.value = this.oldValue;
                    this.setSelectionRange(this.oldSelectionStart, this.oldSelectionEnd);
                }
            });
        }
    });
}

setInputFilter(document.getElementById("HouseNumber"), function (value) {
    return /^\d*$/.test(value);
});

/** Input Filter MODULE - end **/

var box = $(".cardetailmodal-content"), x;
$(".arrow").hover(function () {
    if ($(this).hasClass("scroll-right")) {
        x = ((box.width() / 2)) + box.scrollLeft();
        box.animate({
            scrollLeft: x,
        })
    } else {
        x = ((box.width() / 2)) - box.scrollLeft();
        box.animate({
            scrollLeft: -x,
        })
    }
})

/********** DOMREADY ****************************************/
var box = $(".cardetailmodal-content"), x;
$(".arrow").hover(function () {
    if ($(this).hasClass("scroll-right")) {
        x = ((box.width() / 2)) + box.scrollLeft();
        box.animate({
            scrollLeft: x,
        })
    } else {
        x = ((box.width() / 2)) - box.scrollLeft();
        box.animate({
            scrollLeft: -x,
        })
    }
})
$(function () {
    if (win.width() < 990) {
        $(".js-toggle").click(function () {
            $(this).parent().parent().toggleClass("active");
        });
    }
    if ($('.header-sticky').length > 0) {
        win.on('load scroll', toggleStickyHeader);
    }

    if ($('#search').length > 0) {
        $('#search').on("click", function (e) {
            e.preventDefault();
            $("#hidden-section").toggle();
            $('#header').addClass("search-open");
            setTimeout(function () { window.scrollTo(0, 0); }, 10);
        });
        $('.close-search').on("click", function (e) {
            e.preventDefault();
            $("#hidden-section").hide();
            $('#header').removeClass("search-open");
            setTimeout(function () { window.scrollTo(0, 0); }, 10);
        });

        if ($(".search-section form").attr("action") == window.location.href.split("?")[0]) {
            var urlParams = new URLSearchParams(window.location.search);
            var searchString = urlParams.get('q');
            $("#hidden-section input").val(searchString);
            $("#hidden-section").show();
            $('#header').addClass("search-open");
            setTimeout(function () { window.scrollTo(0, 0); }, 10);

        }
    }

    // CodeChecker
    $('.codeCheckerHolder').hide();

    // Don't allow same-page interactive links to alter the URL hash
    $('body').on('click', 'a[href$="#"]', function (e) { e.preventDefault(); });

    if (($('.page-caroverview').length > 0) && (window.location.hash != '')) {
        loadFiltersFromDeeplink();
    } else if (($('.page-caroverview').length > 0) && fromSession) {
        // Filters have been loaded from the user's browser session, make sure the URL is updated
        updateOverviewUrlHash();
    }

    if ($('.page-cardetail').length > 0) {
        $('.js-desc-showall').on('click', function () {
            $('.options-full li:nth-of-type(n + 5)').css({
                'display': 'block'
            });
            $('.options-full').removeClass('shadow');
            $('.js-desc-showall').remove();
        });
    }


    initSameHeight();
    initSameHeightMenu();
    initCustomSelects();
    initCollapsiblePanels();
    initCarToggleMainnav();
    initCarSlideshows();
    initCarStickyOptions();
    initCarSubscriptionInfo();
    initCarFilters();
    initValidation();
    initMobileFixedElemsFix();
    initDatepickers();
    initB2Echoice();
    initB2EDropout();
    initVideoOverlay();
    initCarCustomizeMobile();
    wrapContentTables();
    objectFitImages();

    initSearchRequestSliders();
    selectableOpenClose();
    initSlider();

    initCarSearchRequest();

    if ($('.address-finder').length > 0) {
        initAddressFinder();
    }
    if (jQuery('.slides-fans-quotes li').length > 1) { initDetailFanSlideshows(); }

    // Testing purposes only, turn off for live!
    //orderCarFormAbTest();

    $('#carvariant-compare').on('shown.bs.modal', equalHeightRowsForCompareModal);

    $('.checkbox-terms a').on('click', openLinkContentInModal);
    $('.checkbox-terms-js a').on('click', openLinkContentInModal);

   $('.js-reviews').flickity({
        // options
        cellAlign: 'left',
        contain: true,
        watchCSS: true,
        prevNextButtons: false,
        pageDots: false
    });

});

//On load for better performance, animation stutters when on domready
jQuery(window).on('load', function () {
    initCampaignBanner();

    // For some reason the events in this function don't bind properly if it is called in the list above (DOMready)
    initCarOverviewFilters();
});

$(document).ready(function () {
    if (jQuery('.slides-carmedia li').length > 1) {
        thumbDiv = document.querySelector('.slidesnav-carmedia-thumbs');
        initDetailMediaSlideshow();

        // Set up initialization for the modal box attached to the occasions detail media slideshow
        var oModalCarMedia = jQuery('.carmedia-modal');
        if (oModalCarMedia.length > 0) {
            oModalCarMedia.on('shown.bs.modal', function (event) {
                if (typeof oModalMediaSlider !== 'undefined') {
                    oModalMediaSlider.redrawSlider();
                }

                initDetailModalSlideshow(event);

                var resizeEvent = new Event('resize');
                window.dispatchEvent(resizeEvent);
            });
            oModalCarMedia.on('hide.bs.modal', () => { 
                $('body').off('keydown', carModalKeyNav); 

                const carName = oModalMediaSlider[0].closest('[data-mtm-modal]')?.dataset['mtmModal'] || '';

                trackSearchFilters({
                id: 'cardetailImg',
                action: 'close',
                category: 'cardetail-media',
                name: carName,
                value: oModalMediaSlider.getCurrentSlide(),
                });
            });

            oModalCarMedia.on('hidden.bs.modal', () => {
                var iCurrentSlide = oModalMediaSlider.getCurrentSlide();
                oCarMediaSlider.goToSlide(iCurrentSlide);
            });
        }
    }

    initTooltip();
})



/********** REGULAR FUNCTIONS *******************************/

/**
 * 
 * @param {*} event 
 */
function trackSearchFilters(event) {
    _mtm.push({
        event: event.id,
        eventCategory: event.category,
        eventAction: event.action,
        eventName: event.name,
        eventValue: isEmpty(event.value) ? "" : event.value
    });
}

function isEmpty(value) {
    return value === '' || value === null || typeof value === 'undefined';
}

function initCarFilters() {
    if ($('.caroverview-filters').length > 0) {
        // Disabled input fields (in this case, checkboxes) can't be posted;
        // So make sure that their values are stored in hidden fields as well
        $('.caroverview-filters .form-group[data-mtm-group]').on('click', '.checkbox input[type=checkbox]', (e) => {
            // get parent, groupname, prevValue, selected items
            const parent = e.delegateTarget,
                checkbox = e.currentTarget,
                groupName = parent.dataset['mtmGroup'];
            
            persistDisabledFilters.call(checkbox);

            // push the change to Matomo
            const type = checkbox.closest('[data-mtm-type]')?.dataset['mtmType'];
            if(type === 'filter') {
              trackSearchFilters({
                id: type,
                action: groupName,
                category: checkbox.checked ? 'filter-add' : 'filter-remove',
                name: checkbox.value
              });
            }
        });

        // Disable checkbox labels
        $('.checkbox').each(function () {
            if ($(this).find('input').prop('disabled')) {
                $(this).find('label').addClass('disabled');
            }
        });

        // Delete selected filters
        $('.filter-label-close').on('click', removeOverviewFilter);
        if ($('.filter-list .filter-label').length > 0) { $('.delete-all-filters').css('display', 'inline-block'); }
        $('.filter-label-deleteAll').on('click', clearOverviewFilters);

        // Refresh results
        if (win.width() <= 991) {
            // iPad portrait and smartphone resolutions - don't refresh until the user submits the selected filters
            $('.filter-label-close').on('click', toggleOverviewMobileFilters);
            $('.apply-selectedfilters').on('click', submitOverviewFilters);
        } else {
            // iPad landscape and desktop resolutions - instantly load results when a filter is altered
            $('.caroverview-filters input[type="checkbox"], .caroverview-filters select').on('change', submitOverviewFilters);
        }

        // Sorting the results should always perform an AJAX request instantaneously no matter the device type
        $('.caroverview-results select').on('change', submitOverviewFilters);
    }

    if (win.width() <= 991) {
        $('.apply-quick-filter').on('click', function () {
            if (this.classList.contains('active')) {
                this.classList.remove('active');
            } else {
                this.classList.add('active');
            }

            setTimeout(function () {
                $('.apply-selectedfilters').click();
            }, 1000);
        });
    }
}

$(window).resize(function () {
    if (oCarMediaSlider !== null) {
        if (oCarThumbsSlider !== null && oCarThumbsSlider !== undefined && oCarThumbsSlider.length > 0) {
            oCarThumbsSlider.destroySlider();
        }
        if (oCarMediaSlider !== undefined && oCarMediaSlider.length > 0) {
            oCarMediaSlider.destroySlider();
        }

        var tumbWrapper = document.querySelector('.wrapper-slidesnav-carmedia-thumbs');
        if (tumbWrapper !== null) {
            tumbWrapper.appendChild(thumbDiv);
        }

        initDetailMediaSlideshow();
    }
});

function initDetailMediaSlideshow() {
    const oCarMedia = jQuery('.slides-carmedia');

    if (typeof oCarMedia === 'undefined') {
        return false;
    }
    const carName = oCarMedia.closest('[data-mtm-slider]')[0]?.dataset['mtmCar'] || "";

    oCarMediaSlider = oCarMedia.bxSlider({
        auto: false,
        slideMargin: 1,
        minSlides: 1,
        maxSlides: 1,
        moveSlides: 1,
        nextSelector: '.slides-carmedia-control.next',
        prevSelector: '.slides-carmedia-control.prev',
        infiniteLoop: true,
        hideControlOnEnd: true,
        pagerCustom: '.slidesnav-carmedia-thumbs',
        onSliderLoad: function (currentIndex) {
            oCarMedia.find('[data-slide-index="' + currentIndex + '"]').addClass('active');

            // If the user clicks on the next/prev slide, focus that slide before opening the modal box
            syncCarMediaSliderAndModal(oCarMedia);

            //add tracking to all slides
            const slides = oCarMedia.find('[data-slide-index]');
            $.each(slides, (index, slide) => {
                slide.addEventListener('click', () => {
                    trackSearchFilters({
                        id: 'cardetailImg',
                        action: 'enlarge',
                        category: 'cardetail-media',
                        name: carName,
                        value: index
                    });
                })
            })
        },
        onSlideBefore: function (oSlider, oldIndex, newIndex) {
            // Make sure the newIndex is a number
            newIndex = parseInt(newIndex);

            oCarMediaSlider.find('.car-media').removeClass('active');
            oCarMediaSlider.find('[data-slide-index="' + newIndex + '"]').addClass('active');
            
            // If the user clicks on the next/prev slide, focus that slide before opening the modal box
            syncCarMediaSliderAndModal(oCarMediaSlider);

            var iMoveDir, oNewSlide;

            if (newIndex > oldIndex) {
                iMoveDir = 1;
            } else {
                iMoveDir = -1;  // Should never occur, added just in case (moving backwards past the first slide has been disabled)
            }

            if ((newIndex - oldIndex) > 1) {
                // When the user jumps ahead by multiple slides using the thumbnail nav,
                // make sure all the slides in between the old and new one are loaded

                for (var iSlide = oldIndex; iSlide < (newIndex + 1); iSlide++) {
                    oNewSlide = oCarMediaSlider.find('[data-slide-index="' + (iSlide + 1) + '"] img');
                    if (oNewSlide.attr('src') == '') {
                        oNewSlide.attr('src', oNewSlide.attr('data-src'));
                    }

                    // If this slide wasn't loaded yet on the modal box slideshow, make sure it is
                    oModalNewSlide = jQuery('.modal-slides-carmedia [data-slide-index="' + (iSlide + 1) + '"] img');
                    if (oModalNewSlide.attr('src') == '') {
                        oModalNewSlide.attr('src', oModalNewSlide.attr('data-src'));
                    }
                }
            } else {
                // The slide before the previous one or after the next one may now be loaded
                oNewSlide = oCarMediaSlider.find('[data-slide-index="' + (newIndex + iMoveDir) + '"] img');
                if (oNewSlide.attr('src') == '') {
                    oNewSlide.attr('src', oNewSlide.attr('data-src'));
                }

                // If this slide wasn't loaded yet on the modal box slideshow, make sure it is
                oModalNewSlide = jQuery('.modal-slides-carmedia [data-slide-index="' + (newIndex + iMoveDir) + '"] img');
                if (oModalNewSlide.attr('src') == '') {
                    oModalNewSlide.attr('src', oModalNewSlide.attr('data-src'));
                }
            }

            // If the user advances outside of the view of the thumbnails slider by using the main slider's prev/next
            // arrows, make sure the thumbnails slider moves along too
            if (jQuery('.slidesnav-carmedia-thumbs').parent().hasClass('bx-viewport')) {
                var oCarThumbsSlider = jQuery('.slidesnav-carmedia-thumbs').data().bxInstance;

                var iPrevSlides = 9;

                // Prevent whitespace appearing next to the final slide by adjusting the goToSlide number
                if ((newIndex + 1) == oCarMediaSlider.getSlideCount()) { iPrevSlides++; }

                // To ensure the previous thumbnails don't disappear from the viewport rendering the
                // thunbmail pager useless, always move to a slide which comes before the one clicked
                // (this slideshow has ~ 11 slides in the viewport so move back 9 for more elegance)
                oCarThumbsSlider.goToSlide(newIndex - iPrevSlides);
            }
        },
        onSlideNext: ($element, oldIndex, newIndex) => {
          trackSearchFilters({
            id: 'cardetailImg',
            action: 'next',
            category: 'cardetail-media',
            name: carName,
            value: newIndex
          });
        },
        onSlidePrev: ($element, oldIndex, newIndex) => {
          trackSearchFilters({
            id: 'cardetailImg',
            action: 'previous',
            category: 'cardetail-media',
            name: carName,
            value: newIndex
          });
        }
    });

    initCarThumbsSlider(oCarMediaSlider);
}

function initCarThumbsSlider(oCarMediaSlider) {
    var oCarThumbs = jQuery('.slidesnav-carmedia-thumbs');

    createCarThumbsSlider(oCarThumbs, oCarMediaSlider);
}

function createCarThumbsSlider(oThumbsSlider, oMainSlider) {
    const carName = oMainSlider.closest('[data-mtm-slider]')[0]?.dataset['mtmCar'] || "";
    oCarThumbsSlider = oThumbsSlider.bxSlider({
        auto: false,
        slideMargin: 2,
        slideWidth: 59,
        minSlides: 1,
        maxSlides: 1,
        moveSlides: 1,
        controls: false,
        pager: false,
        infiniteLoop: false,
        touchEnabled: true,
        onSliderLoad: function (currentIndex) {
            var oThumbLinks = oThumbsSlider.find('a');

            oThumbLinks.on('click', function () {
                var oClickedThumb = jQuery(this);

                var iPrevSlides = 9;
                var iClickedSlide = parseInt(oClickedThumb.attr('data-slide-index'));

                // Prevent whitespace appearing next to the final slide by adjusting the goToSlide number
                if ((iClickedSlide + 1) == oCarThumbsSlider.getSlideCount()) { iPrevSlides++; }

                // To ensure the previous thumbnails don't disappear from the viewport rendering the
                // thunbmail pager useless, always move to a slide which comes before the one clicked
                // (this slideshow has ~ 11 slides in the viewport so move back 9 for more elegance)
                oCarThumbsSlider.goToSlide(iClickedSlide - iPrevSlides);

                trackSearchFilters({
                    id: 'cardetailThumbnail',
                    action: 'click image thumbnail',
                    category: 'cardetail-media',
                    name: carName,
                    value: iClickedSlide
                });
            });

            // Remove temporary CSS from the thumbs now that the slider has initialized
            oThumbLinks.removeClass('uninit').animate({ opacity: 1 });

            // Find out which slide is currently active in the media slideshow
            var iSyncIdx = oMainSlider.getCurrentSlide();

            // After initialization of the thumbsnav make sure that the correct thumb is set to appear active
            oThumbLinks.filter('[data-slide-index="' + iSyncIdx + '"]').addClass('active');
        }
    });
}

function initDetailModalSlideshow(e) {
    var oCurrSlide = jQuery(e.relatedTarget);
    var iCurrSlide = oCurrSlide.attr('data-slide-index');
    var oModalMedia = jQuery('.modal-slides-carmedia');
    const carName = oModalMedia[0].closest('[data-mtm-modal]')?.dataset['mtmModal'] || '';

    // Only initialize the slideshow the first time the modal box opens
    if (!oModalMedia.parent().hasClass('bx-viewport')) {
        oModalMediaSlider = oModalMedia.bxSlider({
            auto: false,
            slideMargin: 1,
            minSlides: 1,
            maxSlides: 1,
            moveSlides: 1,
            nextSelector: '.modal-slides-carmedia-control.next',
            prevSelector: '.modal-slides-carmedia-control.prev',
            infiniteLoop: true,
            hideControlOnEnd: true,
            adaptiveHeight: true,
            video: true,
            touchEnabled: false,
            pagerCustom: '.slidesnav-carmedia-thumbs-modal',
            onSliderLoad: function (currentIndex) {
                // Fade in the slides (hidden initially to prevent jumpy initialization)
                oModalMedia.find('img').animate({ opacity: 1 });

                oModalMedia.goToSlide(iCurrSlide);

                oModalMedia.find('li:not(.bx-clone)').eq(iCurrSlide).addClass('current');

                jQuery('body').on('keydown', carModalKeyNav);

                oModalMedia.find('li').on('click', function () {
                    oModalMediaSlider.goToSlide(jQuery(this).attr('data-slide-index'));
                });

                // If the user clicks on the next/prev slide, focus that slide before opening the modal box
                syncCarMediaSliderAndModal(oModalMedia);

                if (!hasVivitionFullyLoaded && document.querySelector('.vivition-player')) {
                    setTimeout(function () {
                        loadVivitionPlayer();
                    }, 0);
                }
            },
            onSlideBefore: function (oSlider, oldIndex, newIndex) {
                // Make sure the newIndex is a number
                newIndex = parseInt(newIndex);

                var oCurrSlide = oModalMedia.find('li:not(.bx-clone)').eq(newIndex);
                var oMiscSlides = oCurrSlide.siblings('li:not(.bx-clone)');

                oCurrSlide.addClass('current');
                oMiscSlides.removeClass('current');

                // If the user clicks on the next/prev slide, focus that slide before opening the modal box
                syncCarMediaSliderAndModal(oCarMediaSlider);

                var iMoveDir, oNewSlide;

                if (newIndex > oldIndex) {
                    iMoveDir = 1;
                } else {
                    iMoveDir = -1;  // Should never occur, added just in case (moving backwards past the first slide has been disabled)
                }

                if ((newIndex - oldIndex) > 1) {
                    // When the user jumps ahead by multiple slides using the thumbnail nav,
                    // make sure all the slides in between the old and new one are loaded

                    for (var iSlide = oldIndex; iSlide < (newIndex + 1); iSlide++) {
                        oNewSlide = oModalMedia.find('[data-slide-index="' + (iSlide + 1) + '"] img');
                        if (oNewSlide.attr('src') == '') {
                            oNewSlide.attr('src', oNewSlide.attr('data-src'));
                        }

                        // If this slide wasn't loaded yet on the main slideshow, make sure it is
                        oMainNewSlide = jQuery('.slides-carmedia [data-slide-index="' + (iSlide + 1) + '"] img');
                        if (oMainNewSlide.attr('src') == '') {
                            oMainNewSlide.attr('src', oMainNewSlide.attr('data-src'));
                        }
                    }
                } else {
                    // The slide before the previous one or after the next one may now be loaded
                    oNewSlide = oModalMedia.find('[data-slide-index="' + (newIndex + iMoveDir) + '"] img');
                    if (oNewSlide.attr('src') == '') {
                        oNewSlide.attr('src', oNewSlide.attr('data-src'));
                    }

                    // If this slide wasn't loaded yet on the main slideshow, make sure it is
                    oMainNewSlide = jQuery('.slides-carmedia [data-slide-index="' + (newIndex + iMoveDir) + '"] img');
                    if (oMainNewSlide.attr('src') == '') {
                        oMainNewSlide.attr('src', oMainNewSlide.attr('data-src'));
                    }
                }

                // If the user advances outside of the view of the thumbnails slider by using the main slider's prev/next
                // arrows, make sure the thumbnails slider moves along too
                if (jQuery('.slidesnav-carmedia-thumbs-modal').parent().hasClass('bx-viewport')) {
                    var oCarThumbsSlider = jQuery('.slidesnav-carmedia-thumbs-modal').data().bxInstance;

                    var iPrevSlides = 9;

                    // Prevent whitespace appearing next to the final slide by adjusting the goToSlide number
                    if ((newIndex + 1) == oModalMediaSlider.getSlideCount()) { iPrevSlides++; }

                    // To ensure the previous thumbnails don't disappear from the viewport rendering the
                    // thunbmail pager useless, always move to a slide which comes before the one clicked
                    // (this slideshow has ~ 11 slides in the viewport so move back 9 for more elegance)
                    oCarThumbsSlider.goToSlide(newIndex - iPrevSlides);
                }
            },
            onSlideNext: ($element, oldIndex, newIndex) => {
              trackSearchFilters({
                id: 'cardetailImg',
                action: 'next',
                category: 'cardetail-media',
                name: carName,
                value: newIndex
              });
            },
            onSlidePrev: ($element, oldIndex, newIndex) => {
              trackSearchFilters({
                id: 'cardetailImg',
                action: 'previous',
                category: 'cardetail-media',
                name: carName,
                value: newIndex
              });
            }
        });
        setTimeout(function () {
            oModalMediaSlider.redrawSlider();
        }, 100);
    } else {
        oModalMediaSlider.goToSlide(iCurrSlide);

        jQuery('body').on('keydown', carModalKeyNav);
    }

    initDetailModalTumbsSlider(oCarMediaSlider);
}

function initDetailModalTumbsSlider(oCarMediaSlider) {
    var oCarThumbs = jQuery('.slidesnav-carmedia-thumbs-modal');

    createCarModalThumbsSlider(oCarThumbs, oCarMediaSlider);
}

function createCarModalThumbsSlider(oThumbsSlider, oMainSlider) {
    const carName = oThumbsSlider.closest('[data-mtm-modal]')[0]?.dataset['mtmModal'] || '';
    oCarThumbsSliderModal = oThumbsSlider.bxSlider({
        auto: false,
        slideMargin: 2,
        slideWidth: 59,
        minSlides: 1,
        maxSlides: 1,
        moveSlides: 1,
        controls: false,
        pager: false,
        infiniteLoop: false,
        touchEnabled: true,
        onSliderLoad: function (currentIndex) {
            var oThumbLinks = oThumbsSlider.find('a');

            oThumbLinks.on('click', function () {
                var oClickedThumb = jQuery(this);

                var iPrevSlides = 9;
                var iClickedSlide = parseInt(oClickedThumb.attr('data-slide-index'));

                // Prevent whitespace appearing next to the final slide by adjusting the goToSlide number
                if ((iClickedSlide + 1) == oCarThumbsSliderModal.getSlideCount()) { iPrevSlides++; }

                // To ensure the previous thumbnails don't disappear from the viewport rendering the
                // thunbmail pager useless, always move to a slide which comes before the one clicked
                // (this slideshow has ~ 11 slides in the viewport so move back 9 for more elegance)
                oCarThumbsSliderModal.goToSlide(iClickedSlide - iPrevSlides);

                trackSearchFilters({
                    id: 'cardetailThumbnail',
                    action: 'click image thumbnail',
                    category: 'cardetail-media',
                    name: carName,
                    value: iClickedSlide
                });
            });

            // Remove temporary CSS from the thumbs now that the slider has initialized
            oThumbLinks.removeClass('uninit').animate({ opacity: 1 });

            // Find out which slide is currently active in the media slideshow
            var iSyncIdx = oMainSlider.getCurrentSlide();

            // After initialization of the thumbsnav make sure that the correct thumb is set to appear active
            oThumbLinks.filter('[data-slide-index="' + iSyncIdx + '"]').addClass('active');
        }
    });
    setTimeout(function () {
        oCarThumbsSliderModal.redrawSlider();
    }, 100);
}

function carModalKeyNav(e) {
    if ((e.keyCode == 37) && (oModalMediaSlider.getCurrentSlide() != 0)) { // left
        oModalMediaSlider.goToPrevSlide();
    } else if ((e.keyCode == 39) && (oModalMediaSlider.getCurrentSlide() != (oModalMediaSlider.getSlideCount() - 1))) { // right
        oModalMediaSlider.goToNextSlide();
    }
}
function syncCarMediaSliderAndModal(oSlider) {
    oSlider.find('.car-media').off('click');

    var oNewPrevSlide = oSlider.find('.car-media.active').parent().prev();
    var oNewNextSlide = oSlider.find('.car-media.active').parent().next();

    oNewPrevSlide.find('.car-media').on('click', function () { jQuery('.slides-carmedia-control.prev a').trigger('click'); });
    oNewNextSlide.find('.car-media').on('click', function () { jQuery('.slides-carmedia-control.next a').trigger('click'); });
}

// This function transforms regular <select> tags into fancy versions of themselves
function initCustomSelects() {
    var oSelects = $('.custom-select:not(.bootstrap-select)');

    oSelects.selectpicker();

    oSelects.on('shown.bs.select', (e, index, selected, previousValue) => {
        const select = e.currentTarget;
        // push the change to Matomo
        const type = select.closest('[data-mtm-type]')?.dataset['mtmType'];
        if(type === 'carsearchFilter') {
            trackSearchFilters({
                id: type,
                action: e.currentTarget.name,
                category: 'car-search-filter-open',
                name: ''
            });
        }
    });

    oSelects.on('changed.bs.select', (e, index, selected, previousValue) => {
        const select = e.currentTarget;
        // push the change to Matomo
        const type = select.closest('[data-mtm-type]')?.dataset['mtmType'];
        if(type === 'carsearchFilter') {
            trackSearchFilters({
                id: type,
                action: select.name,
                category: 'car-search-filter-change',
                name: `from ${previousValue} to ${selected}`
            });
        } else if(type === 'filter') {
            trackSearchFilters({
                id: type,
                action: select.name,
                category: selected === '0' ? 'filter-remove' : 'filter-add',
                name: `from ${previousValue} to ${selected}`
            });
        }
    });
}

// This function sets up all of the events and callbacks for Bootstrap collapse panels
function initCollapsiblePanels() {
    var oCtaBlockContact = $('#collapse-cta-form');

    oCtaBlockContact.on('show.bs.collapse', function () { $(this).addClass('beforeanim'); });
    oCtaBlockContact.on('hidden.bs.collapse', function () { $(this).removeClass('beforeanim'); });

    oCtaBlockContact.on('shown.bs.collapse', function () {
        $(this).removeClass('beforeanim');

        var oScrollTo = $('.cta-block');
        var iStickyHeaderHeight = $('.site-top').outerHeight();

        var iScrollDelay = 0;
        if (WURFL.is_mobile) { iScrollDelay = 750; }

        // Scroll the user to where the CTA block starts, to show as much of the form as possible after the panel expands
        $('html, body').delay(iScrollDelay).animate({
            scrollTop: oScrollTo.offset().top - iStickyHeaderHeight
        });
    });

    oCtaBlockContact.on('hide.bs.collapse', function () {
        var oCollapsePanel = $(this);

        // The transition takes 150ms, so delay collapsing the panel for that long
        oCollapsePanel.addClass('beforeanim').delay(150).queue(function () {
            oCollapsePanel.collapse('hide');
            oCollapsePanel.dequeue();  // Allow future queued animations to perform by removing the current one from the stack
        });
    });

    if ($('.page-cardetail'.length > 0)) {
        oCtaBlockContact.on('shown.bs.collapse hidden.bs.collapse', function () {
            // Only execute the matchHeight feature on desktop (prevents jumpiness on the mobile view)
            if (win.width() >= 768) {
                $.fn.matchHeight._update();
            }
        });

        // Link a couple of elements together for the mobile subscription info collapsible panel to add extra interactiveness to it
        var oSubscriptionInfoMobile = $('#subscription-info-general');
        var oCustomizeHeaderMobile = $('.customize-mobile-header');
        oSubscriptionInfoMobile.on('shown.bs.collapse', function () { oCustomizeHeaderMobile.addClass('subscription-info-active'); })
        oSubscriptionInfoMobile.on('hide.bs.collapse', function () { oCustomizeHeaderMobile.removeClass('subscription-info-active'); })
        oSubscriptionInfoMobile.find('.close').on('click', function () { oSubscriptionInfoMobile.collapse('hide'); });

        var oSubscriptionOptsMobile = $('#subscription-info-options');
        var oSubscriptionOptsTable = $('.wrapper-customize-options .customize-selection');
        oSubscriptionOptsMobile.on('show.bs.collapse', function () {
            var oPanel = $(this);

            // Only move the block the first time the user opens it
            if (oPanel.parent().hasClass('customize-block')) {
                oSubscriptionOptsTable.before(oPanel);
            }
        });
        oSubscriptionOptsMobile.find('.close').on('click', function () { oSubscriptionOptsMobile.collapse('hide'); });

        // Extra KM Mobile Collapse
        var oExtraKmMobileCollapse = $('#subscription-info-morekm');

        oExtraKmMobileCollapse.on('show.bs.collapse', function () {
            var oPanel = $(this);

            // Only move the block the first time the user opens it
            if (oPanel.parent().hasClass('customize-block')) {
                $('.wrapper-customize-options').before(oPanel);
            }
        });

        oExtraKmMobileCollapse.find('.close').on('click', function () { oExtraKmMobileCollapse.collapse('hide'); });
    }
}

// This function trades the "Menu" button in the header for the actual main menu on the car detail desktop view
function initCarToggleMainnav() {
    var oMainnav = $('.site-mainnav');
    var oShowMainnav = $('.show-mainnav');

    oShowMainnav.on('click', function () {
        oMainnav.toggleClass('open');
        $(this).toggleClass('active');
    });
}

// This function initializes the bxSlider slideshow on the lease detail images
function initCarSlideshows(sType) {
    var bInitAll = (typeof sType === 'undefined');
    var oCarImgs = $('.js-cardetail-media .js-detailmedia');
    var oImpressionImgs = $('.cardetail-impression .detailimpression');
    var oTestimonials = $('.cardetail-testimonials .detailtestimonials');
    var oRelatedCars = $('.cardetail-relatedcars .relatedcars');
    var oComparePackages = $('.wrapper-modal-carpackages .carcompare-packages + .carcompare-packages');

    var oDefaultOpts = {
        auto: false,
        slideMargin: 1,
        minSlides: 1,
        maxSlides: 1,
        moveSlides: 1,
        infiniteLoop: true,
        adaptiveHeight: true,
    };

    if (bInitAll || (sType == 'carmedia')) {
        if ((typeof oCarImgsSlider !== 'undefined') && (oCarImgsSlider != null)) {
            oCarImgsSlider.destroySlider();
            oCarImgsSlider = null;

            $('.bx-loading').remove();
        }

        // Add Class to container when there is only 1 slide
        if (oCarImgs.children().length === 1) {
            if (!$('.js-cardetail-media').hasClass('cardetail-media--single')) {
                $('.js-cardetail-media').addClass('cardetail-media--single');
            }
        }

        // Car images
        if (oCarImgs.length > 0) {
            var oCarImgOpts = $.extend({}, oDefaultOpts);
            var videoSlide = oCarImgs.find('.js-detailmedia-slide-video');
            var hasVideo = oCarImgs.find('.js-detailmedia-slide-video').length;

            oCarImgOpts.startSlide = hasVideo ? 1 : 0;
            oCarImgOpts.infiniteLoop = false;
            oCarImgOpts.prevSelector = '.detailmedia-control.prev';
            oCarImgOpts.nextSelector = '.detailmedia-control.next';

            oCarImgOpts.buildPager = function (slideIndex) {
                var oCarImg = oCarImgs.find('li:eq(' + (slideIndex) + ') img');
                var sImageSrc = oCarImg.attr('src');

                var htmlImageThumb = '';
                if (typeof sImageSrc !== 'undefined') {
                    htmlImageThumb = '<img src="' + sImageSrc + '" class="detailmedia-thumb" alt="" />';
                }

                return htmlImageThumb;
            };

            oCarImgOpts.onSliderLoad = function (currentIndex) {
                var fUpdateStyles = function () {
                    var sItemStyles = oCarImgs.find('.bx-clone').attr('style');
                    var oCarImgsSliderSlides = $('.js-cardetail-media .js-detailmedia li:not(.bx-clone)');
                    oCarImgsSliderSlides.attr('style', sItemStyles);

                    clearTimeout(fUpdateStyles);
                };
                setTimeout(fUpdateStyles, 0);
            };

            oCarImgOpts.onSlideBefore = function ($slideElement, oldIndex, newIndex) {
                // Pause the video when sliding away from the video slide (always first slide)
                if (hasVideo && oldIndex === 0) {
                    $('iframe', videoSlide)[0].contentWindow.postMessage('{"event":"command","func":"pauseVideo","args":""}', '*');
                }
            };

            if (oCarImgs.find('.js-detailmedia-slide').length == 1) {
                oCarImgOpts.pager = false;
            }

            oCarImgsSlider = oCarImgs.bxSlider(oCarImgOpts);
        }
    }

    if (bInitAll || (sType == 'impression')) {
        // Impression images
        if (oImpressionImgs.length > 0) {
            var oImpressionImgOpts = $.extend({}, oDefaultOpts);

            oImpressionImgOpts.adaptiveHeight = false;
            oImpressionImgOpts.prevSelector = '.detailimpression-control.prev';
            oImpressionImgOpts.nextSelector = '.detailimpression-control.next';

            oImpressionImgOpts.buildPager = function (slideIndex) {
                var oImpressionImg = oImpressionImgs.find('li:eq(' + (slideIndex + 1) + ') img');
                var sImageSrc = oImpressionImg.attr('src');

                var htmlImageThumb = '';
                if (typeof sImageSrc !== 'undefined') {
                    htmlImageThumb = '<img src="' + sImageSrc + '" class="impression-thumb" alt="" />';
                }

                return '<div class="overlay"></div>' + htmlImageThumb;
            };
        }
    }

    if (bInitAll || (sType == 'testimonials')) {
        // Testimonials
        if (oTestimonials.length > 0) {
            var oTestimonialsOpts = $.extend({}, oDefaultOpts);
            oTestimonialsOpts.controls = false;
        }
    }

    if (bInitAll || (sType == 'relatedcars')) {
        //RelatedCars
        if (oRelatedCars.length > 0) {
            var oRelatedCarsOpts = $.extend({}, oDefaultOpts);
            var iNumOfSlides;
            var iSlideMinMax = $(window).width() <= 767 ? 1 : 2;

            oRelatedCarsOpts.adaptiveHeight = false;
            oRelatedCarsOpts.minSlides = iSlideMinMax;
            oRelatedCarsOpts.maxSlides = iSlideMinMax;
            oRelatedCarsOpts.slideWidth = 255;
            oRelatedCarsOpts.pager = false;
            oRelatedCarsOpts.infiniteLoop = false;
            oRelatedCarsOpts.prevSelector = '.relatedcars-control.prev';
            oRelatedCarsOpts.nextSelector = '.relatedcars-control.next';
            oRelatedCarsOpts.onSliderLoad = function (currentIndex) {
                iNumOfSlides = $('.relatedcars li').length;
                // Hide left arrow if slide is on first slide
                if (currentIndex == 0) {
                    $('.relatedcars-control.prev').addClass('hidden');
                }
                // Hide right arrow if 2 or less slides
                if (iNumOfSlides <= 2) {
                    $('.relatedcars-control.next').addClass('hidden');
                }
            }

            oRelatedCarsOpts.onSlideBefore = function ($slideElement, oldIndex, newIndex) {
                if (newIndex > 0) {
                    $('.relatedcars-control.prev').removeClass('hidden');
                } else {
                    $('.relatedcars-control.prev').addClass('hidden');
                }
                if (newIndex == iNumOfSlides - iSlideMinMax) {
                    $('.relatedcars-control.next').addClass('hidden');
                } else {
                    $('.relatedcars-control.next').removeClass('hidden');
                }
            }
        }
    }

    if (bInitAll || (sType == 'comparepackages')) {
        if ((win.width() <= 991) && (oComparePackages.length > 0)) {
            var oComparePackagesOpts = $.extend({}, oDefaultOpts);

            oComparePackagesSlider = oComparePackages.bxSlider(oComparePackagesOpts);

            // Make sure that the bxSlider is reloaded after the modal box it resides in was opened for the first
            // time, to ensure that it functions properly (otherwise navigating left from slide #1 will cause bugs)
            $('#carvariant-packages').one('shown.bs.modal', function () {
                if (oComparePackagesSlider.length > 1) {
                    var oDefaultOpts = {
                        auto: false,
                        slideMargin: 1,
                        minSlides: 1,
                        maxSlides: 1,
                        moveSlides: 1,
                        infiniteLoop: true,
                        adaptiveHeight: true,
                    };
                    var oComparePackagesOpts = $.extend({}, oDefaultOpts);
                    $(oComparePackages).each(function () {
                        setTimeout($(this).bxSlider(oComparePackagesOpts).reloadSlider(), 500)
                    });
                } else {
                    oComparePackagesSlider.reloadSlider();
                }
            });
        }
    }
}

// A helper method which is envoked from cardetail.ts to recalculate the paging on the modal box
// car packages comparison slider for mobile, after a package may have been removed or added
function reloadPackagesSlider() {
    if (typeof oComparePackagesSlider !== 'undefined') {
        if (oComparePackagesSlider.length > 1) {
            var packages = $('.wrapper-modal-carpackages .carcompare-packages + .carcompare-packages');
            var oDefaultOpts = {
                auto: false,
                slideMargin: 1,
                minSlides: 1,
                maxSlides: 1,
                moveSlides: 1,
                infiniteLoop: true,
                adaptiveHeight: true,
            };
            var oComparePackagesOpts = $.extend({}, oDefaultOpts);
            $(packages).each(function () {
                $(this).bxSlider(oComparePackagesOpts).reloadSlider();
            });
        } else {
            oComparePackagesSlider.reloadSlider();
        }
    }
}

// This function enables the options form on the right-hand side of the lease car detail page to scroll along with the user
function initCarStickyOptions() {
    var oOptionsWrapper = $('.cardetail-customize');
    var oOptionsPane = oOptionsWrapper.find('.customize-block');

    if (oOptionsWrapper.length > 0) {
        if (win.width() > 992) {
            var iOffsetTop = 70;
            if (win.width() < 1200) { iOffsetTop = 0; }

            oOptionsPane.stick_in_parent({
                offset_top: iOffsetTop
            });
        }

        $('.customize-options .onoffswitch-checkbox').on('change', toggleCarOptionObscure);
    }
}

// This function sets up the Bootstrap popover element for the "i" icon on the right-hand side of the lease detail page
function initCarSubscriptionInfo() {
    var oMoreInfoIcons = $('.cardetail-customize .customize-block .more-info:not([data-toggle="collapse"])');

    if (oMoreInfoIcons.length > 0) {
        oMoreInfoIcons.each(function () {
            var oMoreInfoIcon = $(this);
            var oMoreInfoContent = $('.customize-subscription-info[id="' + (oMoreInfoIcon.data('target')).replace('#', '') + '"]');
            var sPopoverId = oMoreInfoContent.attr('id');

            var oPopoverOpts = {
                html: true,
                trigger: 'hover click',
                content: function () {
                    return oMoreInfoContent.html();
                }
            };

            switch (sPopoverId) {
                case 'subscription-info-general':
                    oPopoverOpts.container = '.customize-header';
                    oPopoverOpts.placement = 'bottom';
                    break;

                case 'subscription-info-options':
                    oPopoverOpts.container = '.wrapper-customize-options';
                    oPopoverOpts.placement = 'right';
                    break;
                case 'subscription-info-morekm':
                    oPopoverOpts.container = '.customize-morekm';
                    oPopoverOpts.placement = 'bottom';
                    break;
            }

            oMoreInfoIcon.popover(oPopoverOpts);

            oMoreInfoIcon.on('click mouseenter', (ev) => {
                _mtm.push({
                  'event': 'Tooltip',
                  'eventCategory': 'tooltip-open',
                  'eventAction': ev.type === 'mouseenter' ? 'hover' : ev.type,
                  'eventName': oMoreInfoIcon[0].getAttribute('name'),
                  'eventValue': ''
                });
              });
        
              oMoreInfoIcon.on('hide.bs.popover', (ev) => {
                _mtm.push({
                  'event': 'Tooltip',
                  'eventCategory': 'tooltip-close',
                  'eventAction': ev.type,
                  'eventName': oMoreInfoIcon[0].getAttribute('name'),
                  'eventValue': ''
                });
              });
        });

        $('.customize-block').on('click', '.popover .close', (ev) => { 
            const popover = ev.currentTarget?.closest('.popover');
            const target = $(`[aria-describedby=${popover.id}]`);
            target.popover('hide');
        });
    }
}

function initTooltip() {
    const tooltips = document.querySelectorAll('.info-link');
    
    tooltips.forEach((tooltip) => {
        tooltip.addEventListener('click', (ev) => {
            _mtm.push({
                'event': 'Tooltip',
                'eventCategory': 'tooltip-open',
                'eventAction': ev.type,
                'eventName': tooltip.dataset.mtmName,
                'eventValue': ''
            });
        });    
        
        tooltip.addEventListener('hidden.bs.popover', (ev) => {
            _mtm.push({
                'event': 'Tooltip',
                'eventCategory': 'tooltip-close',
                'eventAction': ev.type,
                'eventName': tooltip.dataset.mtmName,
                'eventValue': ''
              });
        });
    })
}

// This function sets up all of the features of the mobile version of the car overview's filters pane
function initCarOverviewFilters() {
    var oFiltersBtn = $('.btn-togglefilters');

    if (oFiltersBtn.length > 0) {
        $('.caroverview-content').on('touchend click', '.btn-togglefilters', toggleOverviewMobileFilters);
        $('.caroverview-content').on('click', '.overviewfilters-close', toggleOverviewMobileFilters);
        $('.caroverview-content').on('click', '.apply-selectedfilters', toggleOverviewMobileFilters);
    }
}

// This function is part of the callback whenever the overview page's form is submitted through AJAX and
// serves to reflect the applied filters in the URL through a hash, for deeplinking
function updateOverviewUrlHash() {
    var oOverviewFilters = newOverviewFilters();
    var oFields = $('.page-caroverview input[type="checkbox"]:checked, .page-caroverview select.custom-select');

    // Insert all of the form fields' returned values into the object which keeps track of applied filters
    oFields.each(function () {
        var oField = $(this);
        var sFilterType = oField.attr('name');
        var sFilterVal = oField.val();

        if (oField[0].type == 'checkbox') {
            oOverviewFilters[sFilterType].push(sFilterVal);
        } else {
            oOverviewFilters[sFilterType] = sFilterVal;
        }
    });

    // Use all of the filters from the object to build a hash for the URL
    var sHashUrl = '#' + getJsonFilterVals(oOverviewFilters);

    if (sHashUrl != '#') {
        history.replaceState(oOverviewFilters, 'deeplink', sHashUrl);
    } else {
        // If nothing besides the default values for dropdown filters were applied, remove the hash entirely from the URL
        history.replaceState('', 'initialstate', window.location.pathname + window.location.search);
    }
}

// This function is called when the car overview page is loaded with a hash already present in the URL and serves to reflect
// all of the filters that the hash contains on the page before submitting the form to fetch the matching results
function loadFiltersFromDeeplink() {
    var sHashUrl = window.location.hash;
    var oPreloadOverviewFilters = newOverviewFilters();
    var aFilterTypes = (sHashUrl.replace('#', '')).split('&');

    // Iterate through all of the filters passed in the hash URL and make sure they are allocated to the correct
    // positions within the overview filters object initialized above
    for (var iType = 0; iType < aFilterTypes.length; iType++) {
        var aKeyValues = aFilterTypes[iType].split('=');

        if ($.isArray(oPreloadOverviewFilters[aKeyValues[0]])) {
            oPreloadOverviewFilters[aKeyValues[0]] = aKeyValues[1].split(';');
        } else {
            oPreloadOverviewFilters[aKeyValues[0]] = aKeyValues[1];
        }
    }

    // Before continuing, make sure the hash URL didn't only contain default filter values,
    // to avoid making an unnecessary AJAX request
    if (!checkFiltersForOnlyDefVals(oPreloadOverviewFilters)) {
        // Before setting form field values, clear any values which may have been loaded from the user's session
        // to ensure that the results will match with what has been requested in the hash URL
        $('.caroverview-form select.custom-select, .caroverview-form .checkbox input[type="hidden"]').val('');
        $('.caroverview-form input[type="checkbox"]').prop('checked', false);

        // Iterate through the filter types and make sure the form fields match their values
        for (var filterType in oPreloadOverviewFilters) {
            if ($.isArray(oPreloadOverviewFilters[filterType])) {
                var aFilterVals = oPreloadOverviewFilters[filterType];

                for (var iFilter = 0; iFilter < aFilterVals.length; iFilter++) {
                    $('input:checkbox[value="' + aFilterVals[iFilter] + '"]').prop('checked', true);
                    $('input:hidden[id="hid' + filterType + aFilterVals[iFilter] + '"]').val(aFilterVals[iFilter]);
                }
            } else {
                $('select.custom-select[name="' + filterType + '"]').val(oPreloadOverviewFilters[filterType]);
            }
        }

        // Now that all of the form fields reflect their corresponding values from the hash URL, trigger the AJAX request for results
        filterOccasions();
    }
}

// This function is triggered when the visitor navigates to a car detail page without specifying whether
// they would like to view the private or the corporate version of the car and this car has both versions
function showCarChoiceModal() {
    var oCardetailChoiceModal = $('#carvariant-choice');
    oCardetailChoiceModal.modal('show');

    oCardetailChoiceModal.find('.choicemodal-btn').on('click', function () {
        var oBtn = $(this);
        var sChosenType = oBtn.data('choice');

        switch (sChosenType) {
            case 'private':
                // Just close the modal box but make sure to set the cookie
                document.cookie = "CarTarget=0;path=/";
                oCardetailChoiceModal.modal('hide');
                break;
        }
    });
}

// This function serves to prevent fixed elements becoming unfixed when onscreen keyboards on touch
// devices pop up and the user subsequently scrolls the page
function initMobileFixedElemsFix() {
    if ('ontouchstart' in window) {
        var oDoc = $(document);
        var oBody = $('body');

        oDoc.on('focus', 'input:not([type="checkbox"]):not([type="radio"]), textarea', function () { oBody.addClass('unfixedelems'); });
        oDoc.on('blur', 'input:not([type="checkbox"]):not([type="radio"]), textarea', function () { oBody.removeClass('unfixedelems'); });
    }
}

// This function transforms any input elements of type "date" into jQuery UI datepickers
function initDatepickers() {
    // Make sure the datepicker is in Dutch
    $.datepicker.setDefaults($.datepicker.regional['nl']);

    var oDateFields = $('input[type="date"]');
    var oDefOpts = {
        dateFormat: 'dd-mm-yy',
        changeMonth: true,
        changeYear: true
    };

    oDateFields.each(function () {
        var oField = $(this);
        var oFieldOpts = $.extend({}, oDefOpts);

        // Transform the date field into a regular text field to prevent HTML5 datepickers from kicking in
        oField.attr('type', 'text');

        // Exceptions
        if (oField.attr('id') == 'DateOfBirth') {
            oFieldOpts.yearRange = '-100:-18';
            oFieldOpts.minDate = '-100y';
            oFieldOpts.maxDate = '-18y';
            oFieldOpts.defaultDate = '-25y';
        }

        oField.datepicker(oFieldOpts);
    });
}

// This function is triggered if the user visited the business page during a previous session
// and displays a modal box asking whether he/she would like to visit that page again or not
function initB2Echoice() {
    var oB2eChoiceModal = $('#caroffer-choice');
    if (oB2eChoiceModal.length > 0) {
        oB2eChoiceModal.modal('show');

        // When the visitor chooses to view the regular website, remove the business cookie set
        // in the previous b2e session and reload the page
        $('.choicemodal-btn.regular').on('click', function () {
            Cookies.remove('Business');
            window.location.reload(true);
        });
    }
}

function initB2EDropout() {
    //If b2e is in the current Url
    if (window.location.pathname.indexOf("b2e") != -1 || $('.business-title').length) {
        //For each link clicked inside b2e environment
        $('a:not([data-toggle="collapse"], [href=#], [href=""], [href$=".pdf"], .cardetail-print, .dropdown-menu a, .carorder-alterconfig)').on('click', function () {
            //Exclude links in Aanbod and on orderform (normal and that opens in new window or modal)
            if ($(this).parent().hasClass('car-block-wrapper') == false && $(this).attr("target") != '_blank' && $(this).closest('.checkbox-terms').length != 1 && $(this).closest('main.order').length != 1) {
                var sLocation = $(this).attr('href');
                //Check if clicked link leaves the b2e page
                if (sLocation.indexOf("b2e") == -1) {
                    $('#business-dropout').modal("show");
                    $('#business-dropout').on('click', '.choice-yes', function () {
                        Cookies.set('dontInviteToBusiness', '1');
                        window.open(sLocation, '_blank');
                    });
                    return false;
                }
            }
        });

    }
}

function initVideoOverlay() {
    var oTrigger = $('a[data-target="#videoModal"]'),
        oVideoModal = $(oTrigger).data('target');

    oTrigger.on('click', function () {
        var sVideoUrl = $(this).data("videourl");
        $('iframe', oVideoModal).attr('src', sVideoUrl + '&autoplay=1');
    });

    $(oVideoModal).on('hidden.bs.modal', function () {
        $('iframe', oVideoModal).attr('src', '');
    });
}

/********** EVENT HANDLERS **********************************/

// This function checks where on the page the user is and displays or hides the sticky header based on that position
function toggleStickyHeader() {
    var oBody = $('body');
    var oHeader = $('.site-top');
    var oHeaderBtn = $('.sticky-backtotop');

    if (win.width() >= 990 && $('.page-cardetail').length == 0) {
        if (win.scrollTop() >= 66) {
            oBody.addClass('sticky');
            oHeader.addClass('sticky');
            oHeader.css('margin-top', '66px');
        } else {
            oBody.removeClass('sticky cardetail');
            oHeader.removeClass('sticky cardetail');
            oHeader.css('margin-top', 0);
            oHeader.css('top', '');
        }
    } else if ((win.width() > 990) && ($('.page-cardetail').length > 0)) {
        // Car detail sticky header
        if (win.scrollTop() >= 66) {
            oBody.addClass('sticky cardetail');
            oHeader.addClass('sticky cardetail');

            var sPositionDir = 'top';
            var iMarginHeight = 66;
            if (win.width() < 1200) {
                sPositionDir = 'bottom';
            }

            oHeader.css('margin-' + sPositionDir, iMarginHeight + 'px');
        } else {
            oBody.removeClass('sticky cardetail');
            oHeader.removeClass('sticky cardetail');

            var sPositionDir = 'top';
            if (win.width() < 1200) {
                sPositionDir = 'bottom';
            }

            oHeader.css('margin-' + sPositionDir, 0);
            oHeader.css(sPositionDir, '');
        }
    } else if ((win.width() <= 768) && ($('.page-cardetail').length == 0)) {
        if (oHeader.hasClass('cookie-open')) {
            $('.site-container').css('margin-top', 0);
        }
    }

    // Make sure the "Back to top" button does what it should
    if (oHeaderBtn.length > 0) {
        oHeaderBtn.on('click', scrollToTop);
    }
}


// This function takes the user back to the top of the page with a smooth scroll
function scrollToTop(e) {
    e.preventDefault();

    $('html, body').stop().animate({ scrollTop: 0 }, 300);
}


// This function ensures that once a checkbox which has a value has been disabled,
// its value still gets POSTed by copying it to a hidden field if one has been designated
// Best method of invoking: while looping over a set of checkboxes (e.g. $.each())
function persistDisabledFilters() {
    var oChk = jQuery(this);
    var oHidClone = jQuery('#hid' + oChk.attr('id'));   // Find the matching hidden input field

    if (oHidClone.length > 0) {
        oHidClone.val('');  // Reset the hidden field first (no value until proven guilty)

        // Only copy the value to the hidden field if its related checkbox is momentarily
        // both checked and disabled, as otherwise it won't be needed
        if (oChk.is(':checked') && oChk.is(':disabled')) {
            oHidClone.val(oChk.val());
        }
    }
}


function clearDesktopCarFilter() {
    var oCheckboxes = $('.caroverview-filters input:checkbox');
    oCheckboxes.attr('checked', false);

    // Also clear the related hidden checkbox fields in case the checkbox was disabled
    oCheckboxes.each(function () {
        var oCheckboxHid = $('#hid' + $(this).attr('id'));
        oCheckboxHid.val('');
    });

    var oOrigSelects = $('.caroverview-filters select.custom-select, .caroverview-results select.custom-select');
    oOrigSelects.each(function () {
        var oSelect = $(this);
        var sDefaultOpt = oSelect.data('default');
        var oDefaultOpt = oSelect.find('option[data-content="' + sDefaultOpt + '"]');

        // If the specified default option value couldn't be found, revert to the first option in the dropdown
        if (oDefaultOpt.length == 0) { oDefaultOpt = oSelect.find('option').first(); }

        // Reset the <select> element to its initial state before the AJAX call is performed
        oSelect.val(oDefaultOpt.attr('value'));
    });
}


function clearMobileCarFilter() {
    $('.caroverview-filters .hidden-select-value').val('');
}

// This function handles displaying or hiding the car overview's filters pane on mobile
function toggleOverviewMobileFilters() {
    var oBody = $('body');
    var oHtml = $('html');
    var oTrigger = $(this);
    var oFiltersPane = $('.caroverview-filters');

    switch (oTrigger.hasClass('btn-togglefilters')) {
        case true:
            oBody.addClass('disable-scroll');
            oHtml.addClass('disable-scroll');
            oFiltersPane.addClass('active');
            break;

        case false:
            oBody.removeClass('disable-scroll');
            oHtml.removeClass('disable-scroll');
            oFiltersPane.removeClass('active');
            break;
    }
}

// This function makes sure that when a car option becomes active, its label will (probably) not flow behind its price
function toggleCarOptionObscure() {
    var oOption = $(this);
    var oLabel = oOption.parents('tr').find('.option-label');

    if (oOption.is(':checked')) {
        oLabel.addClass('obscured');
    } else {
        var fWaitForSwitchOff = function () {
            oLabel.removeClass('obscured');
            clearTimeout(fWaitForSwitchOff);
        };
        setTimeout(fWaitForSwitchOff, 300);
    }
}

// form validation function
function initValidation() {
    let oDefValidateOpts = {
        errorContainer: $('.form-error'),
        errorPlacement: function (error, element) {
            error.appendTo(element.next('span'));
        }
    };

    if ($('.contactform').length > 0) {
        var oContactValidateOpts = $.extend({}, oDefValidateOpts);
        oContactValidateOpts.ignore = '';

        $('.contactform').validate(oContactValidateOpts);
    }

    if ($('.cta-contact-form').length > 0) { $('.cta-contact-form').validate(oDefValidateOpts); }

    var oCarOrderForm = $('.carorder-form');

    if (oCarOrderForm.length > 0) {
        // If the page was requested with a querystring variable stating that a previous submission may
        // have contained invalid data, make sure the user is alerted of this before trying again
        if (window.location.search.indexOf('invalid=1') !== -1) {
            oCarOrderForm.find('.btn + .form-error').css('display', 'inline');
        }

        var oCarOrderValidateOpts = $.extend({}, oDefValidateOpts);

        oCarOrderValidateOpts.errorPlacement = function (error, element) {
            error.appendTo(element.parent().siblings('span').first());
        };

        oCarOrderValidateOpts.submitHandler = function (form) {
            carOrderSubmit(car, form);
        };

        oCarOrderForm.validate(oCarOrderValidateOpts);
    }

    // Form fields with the capitalize class should have their first letter in uppercase
    var oCapitalizeFields = $('input.capitalize');
    if (oCapitalizeFields.length > 0) {
        oCapitalizeFields.on('blur', function () {
            var oField = $(this);
            var sValue = oField.val();

            oField.val(sValue.charAt(0).toUpperCase() + sValue.slice(1));
        });
    }
}

function initDetailFanSlideshows() {
    var oFanQuotes = jQuery('.slides-fans-quotes');
    var oFanImages = jQuery('.slides-fans-images');

    var oDefSlideOpts = {
        auto: true,
        pause: 5000,
        pagerCustom: '.slides-fans-nav',
        controls: false,
        mode: 'fade'

    };

    oFanQuotes.bxSlider(oDefSlideOpts);
    oFanImages.bxSlider(oDefSlideOpts);
}

// This function is triggered when the user clicks the X to remove an applied filter on the overview page
function removeOverviewFilter() {
    var oTrigger = $(this);
    var value = oTrigger.attr('data-filter-value');
    var type = oTrigger.attr('data-filter-type');
    var name = oTrigger.attr('data-filter-name');

    switch (type) {
        case 'checkbox':
            // Desktop
            $('.caroverview-filters input:checkbox[value="' + value + '"]').prop('checked', false);
            $('.caroverview-filters input:hidden[value="' + value + '"]').val('');

            // Mobile
            $('.caroverview-filters input.hidden-select-value[value="' + value + '"]').val('');
            break;

        case 'dropdown':
            // WARNING: right now there are only 2 dropdown filters and one uses large numbers (e.g. 10000) and
            // the other small numbers (e.g. 24); therefore, it is unlikely that the code below will select a
            // dropdown which doesn't match the correct filter which was clicked on to be deleted. However,
            // if in the future more dropdowns are added and share <option value=""> tags which contain similar
            // values to the existing two dropdowns, behaviour of deleting filters will become unpredicatable

            // Find the dropdown which contains an <option> tag with the filter-label's value and look up the default for that dropdown
            var oSelect = $('.caroverview-filters select.custom-select option[value="' + value + '"]').parent();
            var sDefaultOpt = oSelect.data('default');
            var oDefaultOpt = oSelect.find('option[data-content="' + sDefaultOpt + '"]');

            // If the specified default option value couldn't be found, revert to the first option in the dropdown
            if (oDefaultOpt.length == 0) { oDefaultOpt = oSelect.find('option').first(); }

            // Reset the <select> element to its initial state before the AJAX call is performed
            oSelect.val(oDefaultOpt.attr('value'));
            break;
    }

    // push the change to Matomo
    trackSearchFilters({
        id: 'filter',
        action: name,
        category: 'filter-remove',
        name: value
    });

    $(this).parent().stop().animate({ 'opacity': 0 }, 200, function () {
        var fRemoveFromDOM = function () {
            $(this).remove();
            filterOccasions();

            clearTimeout(fRemoveFromDOM);
        };
        setTimeout(fRemoveFromDOM, 50);
    });
}

// This function is triggered when the user clicks the link specifying it will delete all of the applied filters on the overview page
function clearOverviewFilters() {
    clearDesktopCarFilter();
    clearMobileCarFilter();

    $('.user-filter-selection').stop().animate({ 'opacity': 0 }, 200, function () {
        var fRemoveFromDOM = function () {
            $(this).remove();
            filterOccasions();

            clearTimeout(fRemoveFromDOM);
        };
        setTimeout(fRemoveFromDOM, 50);
    });

    // push the change to Matomo
    trackSearchFilters({
        id: 'filter',
        action: 'all',
        category: 'filter-remove',
        name: this.text
    });
}

// This function can be invoked after the user changes some of the filter or sort controls on the overview page
// and ultimately results in the AJAX request for new results being performed
function submitOverviewFilters() {
    clearMobileCarFilter();
    filterOccasions();
}

// This function is triggered each time the car compare modal box is opened and serves to ensure that
// each row inside the table it holds has columns of equal height
function equalHeightRowsForCompareModal() {
    var oCarCompareTHs = $('.carcompare-table thead th');
    var oCarCompareTDs = $('.carcompare-table tbody td');

    // First remove any inline height styles in case the viewport changed since last time
    oCarCompareTHs.css('height', 'auto');
    oCarCompareTDs.css('height', 'auto');

    oCarCompareTHs.each(function () {
        var oTH = $(this);
        var iTHidx = $(this).index();

        var iTallestHeight = oTH.outerHeight();

        var oCorrespondingTDs = $('.carcompare-table tbody td:nth-child(' + (iTHidx + 1) + ')');
        oCorrespondingTDs.each(function () {
            var oTD = $(this);

            if (oTD.outerHeight() > iTallestHeight) {
                iTallestHeight = oTD.outerHeight();
            }
        });

        if (oTH.outerHeight() < iTallestHeight) {
            oTH.css('height', iTallestHeight + 'px');

            oCorrespondingTDs.css('height', iTallestHeight + 'px');
        }
    });
}

/********** AJAX FUNCTIONS **********************************/

// This function performs the request to fetch results matching the currently applied filters on the overview page
function filterOccasions() {
    if ($('#caroverview-form').length > 0) {
        var timestamp = new Date().getTime();
        const data = $('#caroverview-form').serialize();
        // Lease cars overview with filters
        var ajaxUrl = '/CarSearch/Overview/?target=' + window.CarTarget + '&t=' + timestamp;
        if (window.CarTarget == "WhiteLabel") {
            ajaxUrl = ajaxUrl + "&whitelabelpageid=" + window.WhiteLabelPageId;
        }
        $.ajax({
            url: ajaxUrl,
            type: 'post',
            data: data,
            success: function (response) {
                var oMobileOverviewFilters = $('.caroverview-filters.active');

                // If the mobile filters pane was displayed when this AJAX request was invoked,
                // gracefully move it out of view before loading the response
                if (oMobileOverviewFilters.length > 0) {
                    $('body').removeClass('disable-scroll');
                    oMobileOverviewFilters.removeClass('active');
                    oMobileOverviewFilters.one(getCss3EndEventName('Transition'), function () {
                        loadFilterResults(response);
                    });
                } else {
                    loadFilterResults(response);
                }
            }
        });
    }
    if ($('#caroverview-form-occasion').length > 0) {
        var timestamp = new Date().getTime();
        const data = $('#caroverview-form-occasion').serialize();
        // Lease cars overview with filters
        var ajaxUrl = '/CarSearch/Overview/?isOccassion=true&t=' + timestamp;
        $.ajax({
            url: ajaxUrl,
            type: 'post',
            data: data,
            success: function (response) {
                $('#load-occasions').html(response);
                selectableOpenClose();
                initSlider();
                initSameHeight();

                trackSearchFilters({
                    id: 'carSearch',
                    action: hasValues(data) ? 'filtered' : 'all',
                    category: 'car-search',
                    name: ``
                });
            }
        });
    }
}

/********** HELPER FUNCTIONS ********************************/

/**
 * hasValues - check if data from a form (jquery.Serialize) contains a value
 * @param String formData 
 * @returns Boolean - whether the data in the form contains a value
 */
function hasValues(formData) {
    let hasValue = false;
    const formValues = formData.split('&');

    formValues.forEach((value) => {
        const [key, val] = value.split('=');

        if (val !== '' && val != 0) {
            hasValue = true;
        }
    });

    return hasValue;
}

function loadFilterResults(html) {
    $('#load-caroverview').html(html);
    initCarFilters();
    updateOverviewUrlHash();    // Before the bootstrapSelect objects are initialized to prevent having to jump through hoops to update them
    initCustomSelects();
    initSameHeight();
}

// This function returns a browser-specific event name for when transitions or animations finish
// @sType - string referring to the event type which should be retrieved (must start with a capital letter)
function getCss3EndEventName(sType) {
    var sTypeLC = sType.toLowerCase();
    var iType, undefined, oElem = document.createElement('div');

    var aEventNames = {};
    aEventNames['Webkit' + sType] = 'webkit' + sType + 'End';   // WebKit
    aEventNames['Moz' + sType] = sTypeLC + 'end';               // Mozilla
    aEventNames['O' + sType] = 'o' + sTypeLC + 'end';           // Old Opera
    aEventNames['ms' + sType] = sTypeLC + 'end';           // Internet Explorer
    aEventNames[sTypeLC] = sTypeLC + 'end';                     // Modern

    for (iType in aEventNames) {
        if (aEventNames.hasOwnProperty(iType) && oElem.style[iType] !== undefined) {
            return aEventNames[iType];
        }
    }
}

// This function serves to return a freshly initialized object for storing overview filters
function newOverviewFilters() {
    var oFilters = {
        'km': 0,
        'looptijd': 0,
        'merk': [],
        'type': [],
        'brandstof': [],
        'transmissie': [],
        'bijtelling': [],
        'sort': 'name'
    };

    return oFilters;
}

// This function returns a string constructed out of filter values stored in JSON
// oAppliedFilters - JSON object storing filters and their values
function getJsonFilterVals(oAppliedFilters) {
    var sHash = '', sPrevArrFilter = '';

    // Before building a hash, check whether the applied filters might only contain default values
    // as in that case there is no point in building a deeplink as it is the page's native state
    if (checkFiltersForOnlyDefVals(oAppliedFilters)) {
        return '';
    }

    // Iterate through the filters object and add the values within each one to the hash URL string
    for (var filterType in oAppliedFilters) {
        // Check whether or not the current filter type contains array values as they require extra logic
        if ($.isArray(oAppliedFilters[filterType])) {
            if (oAppliedFilters[filterType].length > 0) {
                var aFilterVals = oAppliedFilters[filterType];

                // Only start a new filter type in the hash if a different property of the object is
                // being accessed than on the previous iteration
                if ((sHash != '') && (sPrevArrFilter != filterType)) {
                    sHash += '&' + filterType + '=';
                }

                // Iterate through the filter type's array values and add each one to the has URL, delimited by a semicolon
                for (var iFilter = 0; iFilter < aFilterVals.length; iFilter++) {
                    if (iFilter > 0) { sHash += ';'; }

                    sHash += aFilterVals[iFilter];
                }

                sPrevArrFilter = filterType;
            }
        } else {    // Regular filter types with string values
            if (sHash != '') { sHash += '&'; }

            sHash += filterType + '=' + oAppliedFilters[filterType];
        }
    }

    return sHash;
}

// This function holds the contents of a filters object against the stated default values for all filters on the page
// @oFilters - an object with filters which have been applied or which have been requested to be applied through the hash URL
function checkFiltersForOnlyDefVals(oFilters) {
    if (oFilters.bijtelling.length == 0 && oFilters.brandstof.length == 0 && oFilters.transmissie.length == 0 && oFilters.type.length == 0) {
        var oKm = $('select.custom-select[name="km"]');
        var oDuration = $('select.custom-select[name="looptijd"]');
        var oSort = $('select.custom-select[name="sort"]');

        var sDefKmOpt = oKm.find('option[data-content="' + oKm.data('default') + '"]').val();
        var sDefDurationOpt = oDuration.find('option[data-content="' + oDuration.data('default') + '"]').val();
        var sDefSortOpt = oSort.find('option[data-content="' + oSort.data('default') + '"]').val();

        // If all of the dropdown values are equal to their default states, prevent the function from continuing past this point
        if (oFilters.km == sDefKmOpt && oFilters.looptijd == sDefDurationOpt && oFilters.sort == sDefSortOpt) {
            return true;
        }
    }

    return false;
}

/********** GOOGLE ECOMMERCE *******************************/

function carClick(e, product) {
    var url = $(e.currentTarget).attr('href');

    // if Google Tag Manager doesn't respond within 1 second,
    // start sending the user to the next page anyway
    setTimeout(carClickComplete.bind(this, url), 1000);

    // push Google Tag Manager event
    dataLayer.push({
        event: 'carClick', ecommerce: { click: { actionField: { 'list': product.list }, products: [product] } },
        eventCallback: function () { carClickComplete(url); }
    });
    e.preventDefault()
    e.stopPropagation();
    return false;
}

function carClickComplete(url) {
    var urlParts = url.split('#');
    // Set cookie for explicit target choice
    var target = -1;
    if (urlParts.length > 1) {
        var hash = urlParts[1];
        if (hash.startsWith('prive')) {
            target = 0;
        }
    }
    if (target > -1) {
        document.cookie = "CarTarget=" + target + ";path=/";
    }
    document.location = url;
}

function carOrderSubmit(car, form) {
    // Disable submit button, so form cant be submitted twice
    $('button[type="submit"]', form).attr('disabled', 'disabled');

    if (dataLayer) {
        dataLayer.push({
            event: 'checkout',
            ecommerce: {
                checkout: {
                    actionField: { step: 3 },
                    products: [car]
                }
            },
            eventCallback: function () {
                if (!borderCarFormSubmitted) {
                    borderCarFormSubmitted = true;

                    form.submit();
                }
            }
        });
    } else {
        if (!borderCarFormSubmitted) {
            borderCarFormSubmitted = true;

            form.submit();
        }
    }

    return false;
}

// This function sets up the Postcode.nl API
function initAddressFinder() {
    $('.address-finder').each(function () {
        var oWrapper = $(this);

        //oWrapper.addressfinder(sZipcode, '.address-finder-house-number-and-addition', '.address-finder-street', '.address-finder-city', sZipcodeNrsClss, sZipcodeLttrsClss, '.address-finder-result-message', '.address-finder-spinner');

        oWrapper.addressfinder(
            oWrapper.find('.address-finder-post-code-numbers'), oWrapper.find('.address-finder-house-number-and-addition'),
            oWrapper.find('.pccheck-housenr-add'), oWrapper.find('.address-finder-street'),
            oWrapper.find('.address-finder-city'), oWrapper.find('.address-finder-province'), null, null,
            oWrapper.find('.pccheck-indicator'), oWrapper.find('.pccheck-loading'),
            oWrapper.find('.pccheck-feedback'), null, null, false);
    });
}

function openLinkContentInModal(e) {
    var openInModal = $(e.target)[0].target !== "_blank";
    if (openInModal) {
        var href = $(e.target).attr('href');
        var oConditionsModal = $('#conditionsmodal');

        $('#conditionsmodal .modal-body').html('');

        //Add trailing slash to prevent redirect
        if (href.substr(-1) != '/') href += '/';

        $.ajax({
            url: href,
            type: 'GET',
            success: function (data) {
                if ($(data).find('.conditions-content .col-md-8').length) {
                    $('#conditionsmodal .modal-body').html($(data).find('.conditions-content .col-md-8').html());
                } else if ($(data).find('.faq').length) {
                    var pageTitle = $(data).find('h1')[0].textContent; // alleen van de eerste H1 de inhoud pakken

                    $('#conditionsmodal .modal-body').html($(data).find('.faq')[0].outerHTML);
                    $('#conditionsmodal .modal-body').find('.faq__text h2').text(pageTitle);
                }
                else {
                    $('#conditionsmodal .modal-body').html($(data).find('div:not([class]) div[class*=col-]').first().html());
                }
                oConditionsModal.modal('show');
            }
        });

        return false;
    }
    return;
}

function cookieFrameToggle() {
    const selector = {
        video: '.js-consent-video',
        iframe: 'iframe'
    },
        videos = document.querySelectorAll(selector.video);

    videos.forEach((video) => {

        video.classList.add('has-no-consent');

        const iframe = video.querySelector(selector.iframe),
            iframeUrl = iframe.dataset.src;

        epaas.api.fallbackpage.then(
            bundle => bundle.handleFallbackPage(video, iframeUrl).then(
                () => {
                    video.classList.remove('has-no-consent');
                    iframe.src = iframeUrl;
                }
            ).catch(
                (error) => {
                    console.error('Fallback page got an error: ', error.message);
                }
            )
        )
    });
}

((window.epaas && window.epaas.api) || window).addEventListener('consentcontroller.api.initialized', (event) => {
    cookieFrameToggle(event);
});

/********* CAMPAIGN BANNER **********/

function initCampaignBanner() {
    if ($('.campaignbanner').length > 0) {
        if (Cookies.get('campaignbanner') == undefined) {
            $('#collapseCampaign').collapse('show');
        }

        $('#collapseCampaign .close').on('click', function () {
            Cookies.set('campaignbanner', 'closed');
        });
    }
}

/** CAR CUSTOMIZE MOBILE **/
function getParameterByName(name, url) {
    if (!url) url = window.location.href;
    name = name.replace(/[\[\]]/g, "\\$&");
    var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
        results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, " "));
}

function initCarCustomizeMobile() {
    var step = getParameterByName('step');
    if (win.width() < 992) {
        $(".cardetail-customize").hide();
        $(".step3").hide();
    }

    if (typeof step !== 'undefined' && step != '' && step == "step2") {
        $(".cardetail-customize").show();
        $(".cardetail-content").hide();
        $(".step2").addClass("active");
        $(".step1").removeClass("active");
        $(".btn.step2").hide();
        $(".step3").show();
    }

    $(".step1").click(function () {
        $(this).addClass("active");
        $(".step2").removeClass("active");
        $(".cardetail-content").fadeIn();
        $(".cardetail-customize").hide();
        $(".btn.step2").show();
        $(".step3").hide();
        $('html,body').scrollTop(0);
    });

    $(".step2").click(function () {
        $(".step2").addClass("active");
        $(".step1").removeClass("active");
        $(".cardetail-customize").fadeIn();
        $(".cardetail-content").hide();
        $(".btn.step2").hide();
        $(".step3").show();
        $('html,body').scrollTop(0);
    });

    $(window).resize(function () {
        if (win.width() > 992) {
            $(".cardetail-customize").show();
            $(".cardetail-content").show();
            initSameHeightMenu();
            initCarStickyOptions();
            $(".step3").show();
        }

        if (win.width() < 990 && win.width() >= 768) {
            $(".step1").addClass("active");
            $(".step2").removeClass("active");
        }
    });
}

//NEW FILTER JAVASCRIP
var sSliderNoMaxTxt = 'Geen max';

function selectableOpenClose() {
    jQuery('.select').each(function () {
        var textHolder = jQuery(this);

        // Locate the hidden input field which is used to store the selected value
        var hiddenValue = textHolder.find('.hidden-select-value');
        if (hiddenValue.length == 0) {
            // In forms which implement jquery.validate, the hidden field must be placed elsewhere in the
            // DOM in order to trigger the validation rule

            if (textHolder.parents('.wrapper-datepart').length > 0) {
                // When this select belongs to a group of selects meant to form a date (e.g. day-month-year),
                // make sure only the corresponding hidden input field for the current datepart is used
                hiddenValue = textHolder.parents('.wrapper-datepart').first().find('.hidden-select-value');
            } else {
                // Regular forms (with jQuery Validate)
                hiddenValue = textHolder.parents('.form-group').first().find('.hidden-select-value');
            }
        }

        if (textHolder.length) {
            var opener = textHolder.find('.opener');
            var drop = textHolder.find('.dropdown-menu');
            var options = drop.find('a');
            var dropdownHeader = opener.find('span').eq(0);

            if (hiddenValue.val() !== undefined && hiddenValue.val().length > 0) {
                //find the option which refers to the value, and get the correct label from that
                options.each(function () {
                    var option = jQuery(this);
                    if (option.attr('data-id') == hiddenValue.val()) {
                        dropdownHeader.text(option.find('span').text());
                    }
                });
            }

            textHolder
                .find('.opener:not(.clickBound)')
                .addClass('clickBound')
                .on('click', (e) => {
                    e.preventDefault();
                    showDrop();

                    trackSearchFilters({
                        id: 'carsearchFilter',
                        action: textHolder.find("input").attr("name"),
                        category: 'car-search-filter-open',
                        name: ``
                    })
                });

            jQuery(document).on('click', function (e) {
                var target = jQuery(e.target);
                if (drop.is(':visible') && !target.is(opener) && !target.closest(opener).length) {
                    drop.hide();
                    textHolder.removeClass('open');
                }
            });

            function showDrop() {
                if (drop.is(':visible')) {
                    drop.hide();
                    textHolder.removeClass('open');
                } else {
                    drop.show();
                    textHolder.addClass('open');
                }
            }

            options.each(function () {
                var option = jQuery(this);
                option.on('click', function (e) {
                    e.preventDefault();
                    var label = option.find('span').text();

                    if (label != 'Toon' && label != 'Sluit') {
                        dropdownHeader.text(label);
                        hiddenValue.val(option.attr('data-id'));
                        if (!textHolder.hasClass('mobile-car-overview-filter')) {
                            hiddenValue.change();
                        }
                        showDrop();

                        var bIsSearchReq = textHolder.hasClass('car-search-request');

                        if (textHolder.hasClass('mobile-car-overview-filter')) {
                            clearDesktopOccasionFilter();
                            //is a mobile car overview filter, clear the desktop filters and execute the filter
                            filterOccasions();
                        } else if (textHolder.hasClass('car-overview-sort')) {
                            //load occasions
                            loadOccasionOverview(0, true);
                        } else if (textHolder.hasClass('home-car-filter')) {
                            const fieldName = textHolder.find("input").attr("name"),
                                brandName = textHolder.find("input").attr("data-brand"),
                                fieldValue = brandName ? `${brandName} ${label}` : label;

                            // push the change to the GoogleTagManager.dataLayer
                            dataLayer.push({ "carFilterFieldValue": fieldValue });
                            dataLayer.push({ "carFilterFieldName": fieldName });
                            dataLayer.push({ "event": "Car Filter Change" });

                            // push the change to Matomo
                            trackSearchFilters({
                                id: 'carsearchFilter',
                                action: fieldName,
                                category: 'car-search-filter-change',
                                name: label
                            })

                            //load occasions
                            filterOccasions();
                        }
                        else if (bIsSearchReq && textHolder.hasClass('brand')) {
                            filterCarSearchRequestsModels(hiddenValue.val());
                        }
                        else if (bIsSearchReq && textHolder.hasClass('models')) {
                            filterCarSearchRequestsBodyworksAndFuels(hiddenValue.val());
                        }

                        if (bIsSearchReq && (textHolder.hasClass('bodyworks') || textHolder.hasClass('fuels'))) {
                            var oDropdown = $(this).parents('.car-search-request.dropdown-cols').first().find('.hidden-select-value').first();
                            if (oDropdown.hasClass('error') && oDropdown.val() != '') {
                                oDropdown.removeClass('error');
                                oDropdown.parents('.select-holder').first().next('.validate-error').html('');
                            }
                        }
                    }
                });
            });
        }
    });
}

function filterCarSearchRequestsModels(brand) {
    if ($('form.car-search-request-form').length > 0) {
        //car search request form
        $.ajax({
            url: "/CarSearch/CarSearchRequestModels/",
            type: "post",
            data: { brand: brand },
            success: function (response) {
                var oModelCurrSelection = $('.car-search-request.models .opener span:first-child');
                oModelCurrSelection.html(oModelCurrSelection.attr('data-empty'));
                $('.car-search-request.models .drop-nav').html(response);
                selectableOpenClose();

                var oBrandField = $('.car-search-request.brand .hidden-select-value');
                if (oBrandField.hasClass('error')) {
                    oBrandField.removeClass('error');
                    oBrandField.parents('.select-holder').first().next('.validate-error').html('');
                }
            }
        });
    }
}

function filterCarSearchRequestsBodyworksAndFuels(carModel) {
    if ($('form.car-search-request-form').length > 0) {
        var brand = $('.car-search-request.brand input').val();

        //car search request form
        $.ajax({
            url: "/CarSearch/CarSearchRequestBodyWorksAndFuels/",
            type: "post",
            data: { brand: brand, carModel: carModel },
            success: function (response) {
                var oModelCurrSelection = $('.car-search-request.bodyworks .opener span:first-child');
                oModelCurrSelection.html(oModelCurrSelection.attr('data-empty'));

                var oSearchCols = $('form.car-search-request-form .search-col');

                // For syncing up validation messages after reloading the new dropdowns
                var oValidationMsgs = oSearchCols.find('.validate-error');

                oSearchCols.filter('.removeable').remove();
                $('.multiple-dropdowns').before(response);

                var iSearchCol = 0;
                $('form.car-search-request-form .search-col').each(function () {
                    var oSearchCol = $(this);
                    var oCurrValidationMsg = oValidationMsgs.eq(iSearchCol);
                    var htmlMessage = oCurrValidationMsg.html();

                    if (htmlMessage != '') {
                        oSearchCol.find('.hidden-select-value').addClass('error');
                        oSearchCol.find('.validate-error').first().html(htmlMessage);
                    }

                    iSearchCol++;
                });

                var oModelsField = $('.car-search-request.models .hidden-select-value');
                if (oModelsField.hasClass('error')) {
                    oModelsField.removeClass('error');
                    oModelsField.parents('.select-holder').first().next('.validate-error').html('');
                }

                //$('.car-search-request.bodyworks .drop-nav').html(response);
                selectableOpenClose();
            }
        });
    }
}

function initCarSearchRequest() {
    var specificForm = $('.car-search-request-form.specific');
    var notExactForm = $('.car-search-request-form.not-exact');

    $('#collapse-search-request input[name=form-type]').change(function () {
        if (this.value === 'specific') {
            notExactForm.addClass('hidden');
            specificForm.removeClass('hidden');
        }
        else {
            specificForm.addClass('hidden');
            notExactForm.removeClass('hidden');
        }
    });
}

function clearMobileOccasionFilter() {
    $('.occasions-filters .hidden-select-value').val('');
}

function formatAsPrice(iPrice) {
    var sCurrency = '\u20AC';
    var sFormattedPrice = localizeNL(iPrice);
    return sCurrency + ' ' + sFormattedPrice;
}

function localizeNL(iNum) {
    // Use replace() to enforce Dutch locale (just in case)
    return (iNum).toLocaleString().replace(',', '.');
}

function initSlider() {
    var sliderPrice = jQuery('.slider.price');
    var sliderKm = jQuery('.slider.km');
    var sliderYear = jQuery('.slider.year');
    var stepSize, minValue, maxValue, minSelectedValue, maxSelectedValue;

    let bOccOverview = ($('.occasion-overview').length > 0) ? true : false;

    if (sliderPrice.length) {
        stepSize = 50;

        minValue = parseInt($('#slide-price-min').val());
        maxValue = parseInt($('#slide-price-max').val());
        minSelectedValue = $('.slider-price-val-min').val().length > 0 ? parseInt($('.slider-price-val-min').val()) : 0;
        maxSelectedValue = $('.slider-price-val-max').val().length > 0 ? parseInt($('.slider-price-val-max').val()) : 0;

        minValue = Math.floor(minValue / stepSize) * stepSize;
        maxValue = Math.ceil(maxValue / stepSize) * stepSize;

        if (minSelectedValue == 0)
            minSelectedValue = minValue;
        if (maxSelectedValue == 0)
            maxSelectedValue = maxValue;

        sliderPrice.slider({
            range: true,
            min: minValue,
            max: maxValue,
            values: [minSelectedValue, maxSelectedValue],
            step: stepSize,
            slide: function (event, ui) {
                var oMinHandle = sliderPrice.find('.ui-slider-handle em').eq(0);
                var oMaxHandle = sliderPrice.find('.ui-slider-handle em').eq(1);

                var iPreventDragThreshold = 49;

                // Prevent the handlers from overlapping (1000 is the difference in value between the handlers)
                if ((ui.values[0] + iPreventDragThreshold) >= ui.values[1]) {
                    oMinHandle.addClass('extraspace'); oMaxHandle.addClass('extraspace');
                    return false;
                } else {
                    oMinHandle.removeClass('extraspace'); oMaxHandle.removeClass('extraspace');
                }

                var iCurrMaxPos = findSliderHandlePos(oMaxHandle);

                oMinHandle.text(formatAsPrice(ui.values[0]));
                oMaxHandle.text(formatAsPrice(ui.values[1]));

                if (ui.values[0] !== sliderPrice.slider('option', 'min')) {
                    $(".slider-price-val-min").val(ui.values[0]);

                    oMinHandle.removeClass('minvalue');
                } else {
                    $(".slider-price-val-min").val('');

                    oMinHandle.addClass('minvalue');
                }

                if (ui.values[1] !== sliderPrice.slider('option', 'max')) {
                    $(".slider-price-val-max").val(ui.values[1]);

                    // Only manipulate the appearance of the max handle on the occasion overview page
                    oMaxHandle.removeClass('maxvalue');
                } else {
                    sliderPrice.find('.ui-slider-handle em').eq(1).text(sSliderNoMaxTxt);
                    $(".slider-price-val-max").val('');

                    // Only manipulate the appearance of the max handle on the occasion overview page
                    oMaxHandle.addClass('maxvalue');
                }

                // Last check to ensure that the max value doesn't spill out of the container element on the overview page
                if (bOccOverview) {
                    if ((iCurrMaxPos < 92)) {
                        oMaxHandle.removeClass('maxvalue');
                    } else {
                        oMaxHandle.addClass('maxvalue');
                    }
                }

                // If both the min and max values are somehow zero, hide them as they are useless
                if ((ui.values[0] == 0) && (ui.values[1] == 0)) {
                    oMinHandle.parent().addClass('hidden'); oMaxHandle.parent().addClass('hidden');
                } else {
                    oMinHandle.parent().removeClass('hidden'); oMaxHandle.parent().removeClass('hidden');
                }
            },
            change: function (event, ui) {
                const fieldName = $(ui.handle).find('em').data('mtmHandle');
                // push the change to the GoogleTagManager.dataLayer
                dataLayer.push({ "carFilterFieldValue": $(".slider-price-val-min").val() + " - " + $(".slider-price-val-max").val() });
                dataLayer.push({ "carFilterFieldName": 'price' })
                dataLayer.push({ "event": "Car Filter Change" });

                trackSearchFilters({
                    id: 'carsearchFilter',
                    action: fieldName,
                    category: 'car-search-filter-change',
                    name: `${ui.value}`
                });

                clearMobileOccasionFilter();
                filterOccasions();
            }
        });

        jQuery('<span>').appendTo(sliderPrice.find('.ui-slider-handle'));
        jQuery('<em>').prependTo(sliderPrice.find('.ui-slider-handle'));

        const oMinHandle = sliderPrice.find('.ui-slider-handle em').eq(0),
                oMaxHandle = sliderPrice.find('.ui-slider-handle em').eq(1);

            if(!oMinHandle.data('mtmHandle')) {
                oMinHandle.data('mtmHandle','price-min');
            }
            if(!oMaxHandle.data('mtmHandle')) {
                oMaxHandle.data('mtmHandle','price-max');
            }

        oMinHandle.text(formatAsPrice(sliderPrice.slider('values', 0)));

        if ((minSelectedValue + 150) >= maxSelectedValue) {
            oMinHandle.addClass('extraspace'); oMaxHandle.addClass('extraspace');
        }

        if ((minSelectedValue + 100) >= maxSelectedValue) {
            oMinHandle.addClass('extrabigspace'); oMaxHandle.addClass('extraspace');
        }

        if ((minSelectedValue + 50) >= maxSelectedValue) {
            oMinHandle.removeClass('extrabigspace extraspace'); oMaxHandle.removeClass('extrabigspace extraspace');
        }

        if (minSelectedValue == minValue) { oMinHandle.addClass('minvalue'); }

        if (maxSelectedValue == maxValue) {
            oMaxHandle.text(sSliderNoMaxTxt);
        } else {
            oMaxHandle.text(formatAsPrice(sliderPrice.slider('values', 1)));
        }

        var iCurrMaxPos = findSliderHandlePos(oMaxHandle);

        if ((maxSelectedValue == maxValue) || (iCurrMaxPos > 91)) {
            // Only manipulate the appearance of the max handle on the occasion overview page
            oMaxHandle.addClass('maxvalue');
        }

        // If both the min and max values are somehow zero, hide them as they are useless
        if ((minValue == maxValue) || (minValue == maxSelectedValue)) {
            oMinHandle.parent().addClass('hidden'); oMaxHandle.parent().addClass('hidden');
        } else {
            oMinHandle.parent().removeClass('hidden'); oMaxHandle.parent().removeClass('hidden');
        }
    }

    if (sliderKm.length) {
        stepSize = 10000;
        minValue = parseInt($('#slide-milage-min').val());
        maxValue = parseInt($('#slide-milage-max').val());
        minSelectedValue = $('.slider-milage-val-min').val().length > 0 ? parseInt($('.slider-milage-val-min').val()) : 0;
        maxSelectedValue = $('.slider-milage-val-max').val().length > 0 ? parseInt($('.slider-milage-val-max').val()) : 0;

        minValue = Math.floor(minValue / stepSize) * stepSize;
        maxValue = Math.ceil(maxValue / stepSize) * stepSize;

        if (minSelectedValue == 0)
            minSelectedValue = minValue;
        if (maxSelectedValue == 0)
            maxSelectedValue = maxValue;

        sliderKm.slider({
            range: true,
            min: minValue,
            max: maxValue,
            values: [minSelectedValue, maxSelectedValue],
            step: stepSize,
            slide: function (event, ui) {
                var oMinHandle = sliderKm.find('.ui-slider-handle em').eq(0);
                var oMaxHandle = sliderKm.find('.ui-slider-handle em').eq(1);

                var iPreventDragThreshold = (bOccOverview) ? 10000 : 0;

                // Prevent the handlers from overlapping (10000 is the difference in value between the handlers)
                if ((ui.values[0] + iPreventDragThreshold) >= ui.values[1]) {
                    oMinHandle.addClass('extraspace'); oMaxHandle.addClass('extraspace');

                    // Temporarily only prevent the handles from touching on the homepage as the overview
                    // page's KM range can be close to anything due to all the other filters and it won't function properly
                    return false;
                } else {
                    oMinHandle.removeClass('extraspace'); oMaxHandle.removeClass('extraspace');
                }

                var iCurrMaxPos = findSliderHandlePos(oMaxHandle);

                oMinHandle.text(localizeNL(ui.values[0]));
                oMaxHandle.text(localizeNL(ui.values[1]));

                if (ui.values[0] !== sliderKm.slider('option', 'min')) {
                    $(".slider-milage-val-min").val(ui.values[0]);

                    oMinHandle.removeClass('minvalue');
                } else {
                    $(".slider-milage-val-min").val('');

                    oMinHandle.addClass('minvalue');
                }

                if (ui.values[1] !== sliderKm.slider('option', 'max')) {
                    $(".slider-milage-val-max").val(ui.values[1]);

                    // Only manipulate the appearance of the max handle on the occasion overview page
                    oMaxHandle.removeClass('maxvalue');
                } else {
                    sliderKm.find('.ui-slider-handle em').eq(1).text(sSliderNoMaxTxt);
                    $(".slider-milage-val-max").val('');

                    // Only manipulate the appearance of the max handle on the occasion overview page
                    oMaxHandle.addClass('maxvalue');
                }

                // Last check to ensure that the max value doesn't spill out of the container element on the overview page
                if (bOccOverview) {
                    if ((iCurrMaxPos < 92)) {
                        oMaxHandle.removeClass('maxvalue');
                    } else {
                        oMaxHandle.addClass('maxvalue');
                    }
                }

                // If both the min and max values are somehow zero, hide them as they are useless
                if ((ui.values[0] == 0) && (ui.values[1] == 0)) {
                    oMinHandle.parent().addClass('hidden'); oMaxHandle.parent().addClass('hidden');
                } else {
                    oMinHandle.parent().removeClass('hidden'); oMaxHandle.parent().removeClass('hidden');
                }
            },
            change: function (event, ui) {
                const fieldName = $(ui.handle).find('em').data('mtmHandle');
                // push the change to the GoogleTagManager.dataLayer
                dataLayer.push({ "carFilterFieldValue": $(".slider-milage-val-min").val() + " - " + $(".slider-milage-val-max").val() });
                dataLayer.push({ "carFilterFieldName": 'milage' })
                dataLayer.push({ "event": "Car Filter Change" });

                trackSearchFilters({
                    id: 'carsearchFilter',
                    action: fieldName,
                    category: 'car-search-filter-change',
                    name: `${ui.value}`
                });

                clearMobileOccasionFilter();
                filterOccasions();
            }
        });

        jQuery('<span>').appendTo(sliderKm.find('.ui-slider-handle'));
        jQuery('<em>').prependTo(sliderKm.find('.ui-slider-handle'));

        const oMinHandle = sliderKm.find('.ui-slider-handle em').eq(0),
                oMaxHandle = sliderKm.find('.ui-slider-handle em').eq(1);

            if(!oMinHandle.data('mtmHandle')) {
                oMinHandle.data('mtmHandle','km-min');
            }
            if(!oMaxHandle.data('mtmHandle')) {
                oMaxHandle.data('mtmHandle','km-max');
            }

        oMinHandle.text(localizeNL(sliderKm.slider('values', 0)));

        if ((minSelectedValue + 20000) >= maxSelectedValue) {
            oMinHandle.addClass('extraspace'); oMaxHandle.addClass('extraspace');
        }

        // Make sure the min handle looks good on initial load if its value is the minimum
        if (minSelectedValue == minValue) {
            oMinHandle.addClass('minvalue');
        }

        if ((minSelectedValue + 10000) >= maxSelectedValue) {
            oMinHandle.removeClass('extrabigspace extraspace'); oMaxHandle.removeClass('extrabigspace extraspace');
        }

        if (maxSelectedValue == maxValue) {
            oMaxHandle.text(sSliderNoMaxTxt);
        } else {
            oMaxHandle.text(localizeNL(sliderKm.slider('values', 1)));
        }

        var iCurrMaxPos = findSliderHandlePos(oMaxHandle);

        if ((maxSelectedValue == maxValue) || (iCurrMaxPos > 91)) {
            // Only manipulate the appearance of the max handle on the occasion overview page
            oMaxHandle.addClass('maxvalue');
        }

        if ((minValue == maxValue) || (minValue == maxSelectedValue)) {
            oMinHandle.parent().addClass('hidden'); oMaxHandle.parent().addClass('hidden');
        } else {
            oMinHandle.parent().removeClass('hidden'); oMaxHandle.parent().removeClass('hidden');
        }
    }

    if (sliderYear.length) {
        minValue = parseInt($('#slide-year-min').val());
        maxValue = parseInt($('#slide-year-max').val());
        minSelectedValue = $('.slider-year-val-min').val().length > 0 ? parseInt($('.slider-year-val-min').val()) : 0;
        maxSelectedValue = $('.slider-year-val-max').val().length > 0 ? parseInt($('.slider-year-val-max').val()) : 0;
        if (minSelectedValue == 0)
            minSelectedValue = minValue;
        if (maxSelectedValue == 0)
            maxSelectedValue = maxValue;

        sliderYear.slider({
            range: true,
            min: minValue,
            max: maxValue,
            values: [minSelectedValue, maxSelectedValue],
            step: 1,
            slide: function (event, ui) {
                if ((ui.values[0]) >= ui.values[1]) { return false; }

                var oMinHandle = sliderYear.find('.ui-slider-handle em').eq(0);
                var oMaxHandle = sliderYear.find('.ui-slider-handle em').eq(1);

                oMinHandle.text(ui.values[0]);
                oMaxHandle.text(ui.values[1]);

                if (ui.values[0] !== sliderYear.slider('option', 'min')) {
                    $(".slider-year-val-min").val(ui.values[0]);

                    oMinHandle.removeClass('minvalue');
                }
                else {
                    $(".slider-year-val-min").val('');

                    oMinHandle.addClass('minvalue');
                }

                if (ui.values[1] !== sliderYear.slider('option', 'max')) {
                    $(".slider-year-val-max").val(ui.values[1]);

                    // Only manipulate the appearance of the max handle on the occasion overview page
                    oMaxHandle.removeClass('maxvalue');
                } else {
                    sliderYear.find('.ui-slider-handle em').eq(1).text(sSliderNoMaxTxt);
                    $(".slider-year-val-max").val('');

                    // Only manipulate the appearance of the max handle on the occasion overview page
                    oMaxHandle.addClass('maxvalue');
                }

                // If both the min and max values are somehow zero, hide them as they are useless
                if ((ui.values[0] == 0) && (ui.values[1] == 0)) {
                    oMinHandle.parent().addClass('hidden'); oMaxHandle.parent().addClass('hidden');
                } else {
                    oMinHandle.parent().removeClass('hidden'); oMaxHandle.parent().removeClass('hidden');
                }
            },
            change: function (event, ui) {
                const fieldName = $(ui.handle).find('em').data('mtmHandle');

                trackSearchFilters({
                    id: 'carsearchFilter',
                    action: fieldName,
                    category: 'car-search-filter-change',
                    name: `${ui.value}`
                });

                clearMobileOccasionFilter();
                filterOccasions();
            }
        });
        jQuery('<span>').appendTo(sliderYear.find('.ui-slider-handle'));
        jQuery('<em>').prependTo(sliderYear.find('.ui-slider-handle'));

        const oMinHandle = sliderYear.find('.ui-slider-handle em').eq(0),
            oMaxHandle = sliderYear.find('.ui-slider-handle em').eq(1);

            if(!oMinHandle.data('mtmHandle')) {
                oMinHandle.data('mtmHandle','year-min');
            }
            if(!oMaxHandle.data('mtmHandle')) {
                oMaxHandle.data('mtmHandle','year-max');
            }

        oMinHandle.text(sliderYear.slider('values', 0));

        if (maxSelectedValue == maxValue) {
            oMaxHandle.text(sSliderNoMaxTxt);

            // Only manipulate the appearance of the max handle on the occasion overview page
            oMaxHandle.addClass('maxvalue');
        } else {
            oMaxHandle.text(sliderYear.slider('values', 1));
        }

        if ((minValue == maxValue) || (minValue == maxSelectedValue)) {
            oMinHandle.parent().addClass('hidden'); oMaxHandle.parent().addClass('hidden');
        } else {
            oMinHandle.parent().removeClass('hidden'); oMaxHandle.parent().removeClass('hidden');
        }
    }
}
function initSearchRequestSliders() {
    var stepSize, minValue, maxValue, minSelectedValue, maxSelectedValue;
    var sliderBudget = jQuery('.slider.budget');
    var sliderMilage = jQuery('.slider.searchmilage');

    if (sliderBudget.length) {
        $.each(sliderBudget, function (i, slider) {
            var oSlider = $(slider);
            var sFormtype = oSlider[0].parentNode.dataset.formtype;
            stepSize = 1000;

            minValue = parseInt($('#slide-budget-min-' + sFormtype).val());
            maxValue = parseInt($('#slide-budget-max-' + sFormtype).val());
            minSelectedValue = $('.slider-budget-val-min-' + sFormtype).val().length > 0 ? parseInt($('.slider-budget-val-min-' + sFormtype).val()) : 0;
            maxSelectedValue = $('.slider-budget-val-max-' + sFormtype).val().length > 0 ? parseInt($('.slider-budget-val-max-' + sFormtype).val()) : 0;

            minValue = Math.floor(minValue / stepSize) * stepSize;
            maxValue = Math.ceil(maxValue / stepSize) * stepSize;

            if (minSelectedValue == 0)
                minSelectedValue = minValue;
            if (maxSelectedValue == 0)
                maxSelectedValue = maxValue;

            oSlider.slider({
                range: true,
                min: minValue,
                max: maxValue,
                values: [minSelectedValue, maxSelectedValue],
                step: stepSize,
                slide: function (event, ui) {
                    var oMinHandle = oSlider.find('.ui-slider-handle em').eq(0);
                    var oMaxHandle = oSlider.find('.ui-slider-handle em').eq(1);

                    var iPreventDragThreshold = (bOccOverview) ? 2000 : 1000;

                    // Prevent the handlers from overlapping (1000 is the difference in value between the handlers)
                    if ((ui.values[0] + iPreventDragThreshold) >= ui.values[1]) {
                        oMinHandle.addClass('extraspace'); oMaxHandle.addClass('extraspace');
                        return false;
                    } else {
                        oMinHandle.removeClass('extraspace'); oMaxHandle.removeClass('extraspace');
                    }

                    var iCurrMaxPos = findSliderHandlePos(oMaxHandle);

                    oMinHandle.text(formatAsPrice(ui.values[0]));
                    oMaxHandle.text(formatAsPrice(ui.values[1]));


                    $(".slider-budget-val-min-" + sFormtype).val(ui.values[0]);

                    if (ui.values[1] !== oSlider.slider('option', 'max')) {
                        $(".slider-budget-val-max-" + sFormtype).val(ui.values[1]);

                        // Only manipulate the appearance of the max handle on the occasion overview page
                        oMaxHandle.removeClass('maxvalue');
                    } else {
                        oSlider.find('.ui-slider-handle em').eq(1).text(sSliderNoMaxTxt);
                        $(".slider-budget-val-max-" + sFormtype).val('');

                        // Only manipulate the appearance of the max handle on the occasion overview page
                        oMaxHandle.addClass('maxvalue');
                    }

                    // Last check to ensure that the max value doesn't spill out of the container element on the overview page
                    if (bOccOverview) {
                        if ((iCurrMaxPos < 92)) {
                            oMaxHandle.removeClass('maxvalue');
                        } else {
                            oMaxHandle.addClass('maxvalue');
                        }
                    }

                    // If both the min and max values are somehow zero, hide them as they are useless
                    if ((ui.values[0] == 0) && (ui.values[1] == 0)) {
                        oMinHandle.parent().addClass('hidden'); oMaxHandle.parent().addClass('hidden');
                    } else {
                        oMinHandle.parent().removeClass('hidden'); oMaxHandle.parent().removeClass('hidden');
                    }
                }
            });

            jQuery('<span>').appendTo(oSlider.find('.ui-slider-handle'));
            jQuery('<em>').prependTo(oSlider.find('.ui-slider-handle'));

            var oMinHandle = oSlider.find('.ui-slider-handle em').eq(0);
            var oMaxHandle = oSlider.find('.ui-slider-handle em').eq(1);

            oMinHandle.text(formatAsPrice(oSlider.slider('values', 0)));

            if (maxSelectedValue == maxValue) {
                oMaxHandle.text(sSliderNoMaxTxt);

                // Only manipulate the appearance of the max handle on the occasion overview page
                oMaxHandle.addClass('maxvalue');
            } else {
                oMaxHandle.text(oSlider.slider('values', 1));
            }

            if ((minValue == maxValue) || (minValue == maxSelectedValue)) {
                oMinHandle.parent().addClass('hidden'); oMaxHandle.parent().addClass('hidden');
            } else {
                oMinHandle.parent().removeClass('hidden'); oMaxHandle.parent().removeClass('hidden');
            }
        });
    }

    if (sliderMilage.length) {
        $.each(sliderMilage, function (i, slider) {
            var oSlider = $(slider);
            var sFormtype = oSlider[0].parentNode.dataset.formtype;

            stepSize = 10000;
            minValue = parseInt($('#slide-searchmilage-min-' + sFormtype).val());
            maxValue = parseInt($('#slide-searchmilage-max-' + sFormtype).val());
            minSelectedValue = $('.slider-searchmilage-val-min-' + sFormtype).val().length > 0 ? parseInt($('.slider-searchmilage-val-min-' + sFormtype).val()) : 0;
            maxSelectedValue = $('.slider-searchmilage-val-max-' + sFormtype).val().length > 0 ? parseInt($('.slider-searchmilage-val-max-' + sFormtype).val()) : 0;

            minValue = Math.floor(minValue / stepSize) * stepSize;
            maxValue = Math.ceil(maxValue / stepSize) * stepSize;

            if (minSelectedValue == 0)
                minSelectedValue = minValue;
            if (maxSelectedValue == 0)
                maxSelectedValue = maxValue;

            oSlider.slider({
                range: true,
                min: minValue,
                max: maxValue,
                values: [minSelectedValue, maxSelectedValue],
                step: stepSize,
                slide: function (event, ui) {
                    var oMinHandle = oSlider.find('.ui-slider-handle em').eq(0);
                    var oMaxHandle = oSlider.find('.ui-slider-handle em').eq(1);

                    var iPreventDragThreshold = (bOccOverview) ? 10000 : 0;

                    // Prevent the handlers from overlapping (10000 is the difference in value between the handlers)
                    if ((ui.values[0] + iPreventDragThreshold) >= ui.values[1]) {
                        oMinHandle.addClass('extraspace'); oMaxHandle.addClass('extraspace');

                        // Temporarily only prevent the handles from touching on the homepage as the overview
                        // page's KM range can be close to anything due to all the other filters and it won't function properly
                        return false;
                    } else {
                        oMinHandle.removeClass('extraspace'); oMaxHandle.removeClass('extraspace');
                    }

                    var iCurrMaxPos = findSliderHandlePos(oMaxHandle);

                    oMinHandle.text(localizeNL(ui.values[0]));
                    oMaxHandle.text(localizeNL(ui.values[1]));

                    $(".slider-searchmilage-val-min-" + sFormtype).val(ui.values[0]);
                    $(".slider-searchmilage-val-max-" + sFormtype).val(ui.values[1]);

                    // Last check to ensure that the max value doesn't spill out of the container element on the overview page
                    if (bOccOverview) {
                        if ((iCurrMaxPos < 92)) {
                            oMaxHandle.removeClass('maxvalue');
                        } else {
                            oMaxHandle.addClass('maxvalue');
                        }
                    }

                    // If both the min and max values are somehow zero, hide them as they are useless
                    if ((ui.values[0] == 0) && (ui.values[1] == 0)) {
                        oMinHandle.parent().addClass('hidden'); oMaxHandle.parent().addClass('hidden');
                    } else {
                        oMinHandle.parent().removeClass('hidden'); oMaxHandle.parent().removeClass('hidden');
                    }
                }
            });

            jQuery('<span>').appendTo(oSlider.find('.ui-slider-handle'));
            jQuery('<em>').prependTo(oSlider.find('.ui-slider-handle'));

            var oMinHandle = oSlider.find('.ui-slider-handle em').eq(0);
            var oMaxHandle = oSlider.find('.ui-slider-handle em').eq(1);

            oMinHandle.text(localizeNL(oSlider.slider('values', 0)));

            if ((minSelectedValue + 20000) >= maxSelectedValue) {
                oMinHandle.addClass('extraspace'); oMaxHandle.addClass('extraspace');
            }

            // Make sure the min handle looks good on initial load if its value is the minimum
            if (minSelectedValue == minValue) {
                oMinHandle.addClass('minvalue');
            }

            if ((minSelectedValue + 10000) >= maxSelectedValue) {
                oMinHandle.removeClass('extrabigspace extraspace'); oMaxHandle.removeClass('extrabigspace extraspace');
            }

            if (maxSelectedValue == maxValue) {
                oMaxHandle.text(sSliderNoMaxTxt);
            } else {
                oMaxHandle.text(localizeNL(oSlider.slider('values', 1)));
            }

            var iCurrMaxPos = findSliderHandlePos(oMaxHandle);

            if ((maxSelectedValue == maxValue) || (iCurrMaxPos > 91)) {
                // Only manipulate the appearance of the max handle on the occasion overview page
                oMaxHandle.addClass('maxvalue');
            }

            if ((minValue == maxValue) || (minValue == maxSelectedValue)) {
                oMinHandle.parent().addClass('hidden'); oMaxHandle.parent().addClass('hidden');
            } else {
                oMinHandle.parent().removeClass('hidden'); oMaxHandle.parent().removeClass('hidden');
            }
        });
    }
}

// A helper function which calculates the percentage that a jQuery UI slider handle is placed from the left of its container
function findSliderHandlePos(oHandle) {
    var iSliderWidth = oHandle.parents('.num-slider').first().width();
    var iCurrHandlePos = parseInt((oHandle.parent().css('left')).replace('px', ''));

    return Math.round((iCurrHandlePos / iSliderWidth) * 50);
}

//END NEW FILTER JAVASCRIPT

function orderCarFormAbTest() {
    if (window.location.search.indexOf('formulierkort=1') !== -1) {
        var insertHTML = '<div class="row">' +
            '<input type="hidden" name="Gender"      id="gender-male"    data-form-element="01-Gender"       value="Onbekend"/>' +
            '<input type="hidden" name="Gender"      id="gender-female"  data-form-element="02-Gender"       value=""/>' +
            '<input type="hidden" name="Initials"    id="Initials"       data-form-element="03-Initials"     value="."/>' +
            '<input type="hidden" name="DateOfBirth" id="DateOfBirth"    data-form-element="06-DateOfBirth"  value="01-01-1990"/>' +
            '<input type="hidden" name="Street"      id="Street"         data-form-element="09-Street"       value="onbekend"/>' +
            '<input type="hidden" name="HouseNumber" id="HouseNumber"    data-form-element="10-HouseNumber"  value="0"/>' +
            '<input type="hidden" name="ZipCode"     id="ZipCode"        data-form-element="11-ZipCode"      value="0000AA"/>' +
            '<input type="hidden" name="City"        id="City"           data-form-element="12-City"         value="onbekend"/>' +
            '<input type="hidden" name="Remarks"     id="remarks"        data-form-element="13-Remarks"      value="..."/>' +
            '            <div class="col-xs-12">' +
            '                <div class="form-group row">' +
            '                    <label for="FirstName" class="col-sm-12 control-label">Voornaam</label>' +
            '                    <div class="col-sm-12">' +
            '                        <input type="text" name="FirstName" class="form-control capitalize handim-prive-bestel-formulier-veld" data-form-element="04-FirstName" required data-msg-required="*" id="FirstName" maxlength="50" />' +
            '                    </div>' +
            '                    <span class="error"></span>' +
            '                </div>' +
            '                <div class="form-group row">' +
            '                    <label for="LastName" class="col-sm-12 control-label">Achternaam</label>' +
            '                    <div class="col-sm-12">' +
            '                        <input type="text" name="LastName" class="form-control capitalize handim-prive-bestel-formulier-veld" data-form-element="05-LastName" required data-msg-required="*" id="LastName" maxlength="50" />' +
            '                    </div>' +
            '                    <span class="error"></span>' +
            '                </div>' +
            '                <div class="form-group row">' +
            '                    <label for="phone" class="col-sm-12 control-label">Telefoonnummer</label>' +
            '                    <div class="col-sm-12">' +
            '                        <input type="text" name="Phone" class="form-control handim-prive-bestel-formulier-veld" data-form-element="07-Phone" required data-msg-required="*" data-rule-phonenl="true" data-msg-phonenl="*" id="phone" maxlength="20" />' +
            '                    </div>' +
            '                    <span class="error"></span>' +
            '                </div>' +
            '                    <div class="form-group row">' +
            '                        <label for="email" class="col-sm-12 control-label">E-mailadres</label>' +
            '                        <div class="col-sm-12">' +
            '                            <input type="email" name="Email" class="form-control handim-prive-bestel-formulier-veld" data-form-element="08-Email" required data-msg-required="*" data-rule-email="true" data-msg-email="*" id="email" maxlength="255" />' +
            '                        </div>' +
            '                        <span class="error"></span>' +
            '                    </div>' +
            '            </div>' +
            '        </div>';
        $(".carorder-form > fieldset > div:nth-child(1)").html(insertHTML);
    }
}

function wrapContentTables() {
    $('.contentreference table').each(function (index, table) {
        table.classList.add('table');
        $(table).wrap('<div class="table-responsive"></div>');
    });
}

if ($('.config').length > 0) {
    $('.js-desc-config-showall').on('click', function () {
        $('.config_cardetail-options').removeClass('shadow');
        $('.js-desc-config-showall').remove();
    });
}

if ($('.tile__body').length > 0) {
    $('.js-order-showall').on('click', function () {
        $('.tile__body').removeClass('shadow');
        $('.js-order-showall').remove();
    });
}

if ($('.reviews').length > 0) {
    $('.js-readmore').on('click', function () {
        var parent = $(this).parent();
        parent.find('blockquote').addClass('showall');
        $(this).hide();
    });
}